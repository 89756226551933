import { RouteComponentProps, Router } from '@reach/router';
import { useLogout } from '@spaceship-fspl/auth';
import { paddingX } from '@spaceship-fspl/styles';
import { Button } from 'components/button';
import { ProgressHeader } from 'components/progress-header';
import { Routes } from 'pages/routes';
import React from 'react';
import styled from 'styled-components';

export const ProgressNav = ({
  step,
  total,
  headerButtons,
}: RouteComponentProps & {
  step: number;
  total: number;
  headerButtons?: React.ReactElement;
}): JSX.Element => (
  <ProgressHeader step={step} total={total} headerButtons={headerButtons} />
);

// the order of the paths is important
const VOYAGER_SIGNUP_PATHS = [
  Routes.VOYAGER_SIGNUP,
  Routes.VOYAGER_SIGNUP_DETAILS,
];

const VOYAGER_ONBOARDING_PATHS = [
  Routes.VOYAGER_ONBOARDING_PORTFOLIO,
  `${Routes.VOYAGER_ONBOARDING_TMD_FORM}/*`,
  Routes.VOYAGER_ONBOARDING_TAX_RESIDENCY,
  `${Routes.VOYAGER_ONBOARDING_GREENID}/*`,
  `${Routes.VOYAGER_ONBOARDING_BANK_ACCOUNT}/*`,
  `${Routes.VOYAGER_ONBOARDING_INVEST}/*`,
  Routes.VOYAGER_ONBOARDING_SUMMARY,
];

const VOYAGER_ADD_PORTFOLIO_PATHS = [
  Routes.PORTFOLIO_ADD,
  Routes.PORTFOLIO_ADD_TMD,
  Routes.PORTFOLIO_ADD_INVEST_DEPOSIT,
  Routes.PORTFOLIO_ADD_INVEST_INVESTMENT_PLAN,
  Routes.PORTFOLIO_ADD_SUMMARY,
  Routes.PORTFOLIO_ADD_SUCCESS,
];

const SUPER_ONBOARDING_PATHS = [
  Routes.SUPER_SIGNUP_PORTFOLIO,
  Routes.SUPER_SIGNUP_GENDER,
  Routes.SUPER_SIGNUP_TFN,
];

const SUPERMATCH_PATHS = [
  Routes.SUPER_SUPERMATCH,
  Routes.SUPER_SUPERMATCH_TFN,
  `${Routes.SUPER_SUPERMATCH_GREENID}/*`,
  Routes.SUPER_SUPERMATCH_RESULTS,
  Routes.SUPER_SUPERMATCH_TERMS,
  Routes.SUPER_SUPERMATCH_SUCCESS,
];

export const VoyagerSignupNavRoutes: React.FC<React.PropsWithChildren> = () => {
  const totalSteps = VOYAGER_ONBOARDING_PATHS.length;
  return (
    <Router>
      {VOYAGER_SIGNUP_PATHS.map((path, index) => (
        <ProgressNav
          step={index + 1}
          total={totalSteps}
          path={path}
          key={path}
        />
      ))}
    </Router>
  );
};

const StyledNavButton = styled(Button).attrs({
  variant: 'tertiary',
  size: 'lg',
})`
  ${paddingX('md')}
  border-radius: 18px;
  height: 36px;
  min-width: auto;
`;

export const VoyagerOnboardingNavRoutes: React.FC<
  React.PropsWithChildren
> = () => {
  const paths = VOYAGER_ONBOARDING_PATHS;

  const previousSteps = VOYAGER_SIGNUP_PATHS.length;
  const totalSteps = previousSteps + paths.length;

  const logout = useLogout();

  return (
    <Router>
      {paths.map((path, index) => (
        <ProgressNav
          step={previousSteps + index + 1}
          total={totalSteps}
          path={path}
          key={path}
          headerButtons={
            <>
              <StyledNavButton
                trackingProperties={{ name: 'logout' }}
                onClick={logout}
              >
                Log out
              </StyledNavButton>
            </>
          }
        />
      ))}
    </Router>
  );
};

export const VoyagerAddPortfolioNavRoutes: React.FC<
  React.PropsWithChildren
> = () => {
  const totalSteps = VOYAGER_ADD_PORTFOLIO_PATHS.length;

  return (
    <Router>
      {VOYAGER_ADD_PORTFOLIO_PATHS.map((path, index) => (
        <ProgressNav
          step={index + 1}
          total={totalSteps}
          path={path}
          key={path}
        />
      ))}
    </Router>
  );
};

export const SuperOnboardingNavRoutes: React.FC<
  React.PropsWithChildren
> = () => {
  const logout = useLogout();

  return (
    <Router>
      {SUPER_ONBOARDING_PATHS.map((path, index) => (
        <ProgressNav
          step={index + 1}
          total={SUPER_ONBOARDING_PATHS.length}
          path={path}
          key={path}
          headerButtons={
            <>
              <StyledNavButton
                trackingProperties={{ name: 'logout' }}
                onClick={logout}
              >
                Log out
              </StyledNavButton>
            </>
          }
        />
      ))}
    </Router>
  );
};

export const SuperMatchNavRoutes: React.FC<React.PropsWithChildren> = () => (
  <Router>
    {SUPERMATCH_PATHS.map((path, index) => (
      <ProgressNav
        step={index + 1}
        total={SUPERMATCH_PATHS.length}
        path={path}
        key={path}
      />
    ))}
  </Router>
);
