import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import {
  useCanReadSaver,
  useIsAuthenticated,
  useIsVerifiedAccount,
} from '@spaceship-fspl/auth';
import {
  Box,
  Columns,
  Heading,
  Stack,
  Text,
  Visible,
} from '@spaceship-fspl/components';
import { useGetActiveBankAccount } from '@spaceship-fspl/data';
import {
  bankAccountScalars,
  BoostItemGroupStatus,
  investmentSummaryScalars,
  SaverPortfolio,
  SaverReferralShareStatus,
  saverTmdDetailsScalars,
  scheduleScalars,
  unitExchangeScalars,
  unitPriceScalars,
  voyagerPortfolioPerformanceScalars,
  voyagerReferralsCardScalars,
} from '@spaceship-fspl/graphql';
import { WebAppVoyagerCrossSellDashboard } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerCrossSellDashboard';
import {
  WebAppVoyagerDashboard,
  WebAppVoyagerDashboardVariables,
} from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerDashboard';
import { capitalizeWords } from '@spaceship-fspl/helpers';
import { BankAccountStatus } from '@spaceship-fspl/types/externalapi';
import {
  getShowTmdManuallyApprovedCard,
  ShowTmdManuallyApprovedCard_QueryFragment,
} from '@spaceship-fspl/voyager';
import { articleCardFragment, Articles } from 'components/articles';
import { Button } from 'components/button';
import { HeaderWave } from 'components/header-wave';
import { PageContainer } from 'components/layouts/page';
import { RouterLink } from 'components/router-link';
import { TodoCard } from 'components/todo-card';
import { useAuthenticatedNav } from 'contexts/authenticated-nav';
import { isAfter } from 'date-fns';
import {
  DynamicConfigDismissibleId,
  FeatureFlagKeys,
  useFeatureFlag,
} from 'helpers/dynamic-config';
import { useDismissed } from 'helpers/hooks/dismissed';
import { useReferralCardDismiss } from 'helpers/hooks/use-referrals';
import { PersistKey } from 'helpers/persist';
import { voyagerPortfolios } from 'helpers/portfolios';
import { withVoyagerTopNavigation } from 'navigation/helpers';
import { VoyagerCrossSell } from 'pages/account/section/voyager-cross-sell';
import { Routes } from 'pages/routes';
import React, { useEffect } from 'react';

import { BalanceCard } from './balance-card';
import { BoostsCard, voyagerBoostsCardFragment } from './boosts-card';
import { MfaRegistrationCard } from './mfa-registration-card';
import { PortfolioTmdManuallyApprovedCard } from './portfolio-tmd-manually-approved-card';
import { RecentMovers } from './recent-movers';
import { ReferralCampaignCard } from './referral-campaign-card';
import { RecentTransactionsCard } from './transactions';
import { VoyagerUnitPriceCard } from './unit-price';
import {
  UpcomingInvestment,
  UpcomingInvestmentsCard,
} from './upcoming-investments-card';
import { useTodo } from './use-todo';

interface PageParams {
  productId?: string;
}

const Dashboard: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const canReadSaver = useCanReadSaver();

  if (canReadSaver) {
    return <VoyagerAccountDashboard />;
  }

  return <VoyagerCrossSellDashboard />;
};

const VoyagerAccountDashboard: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const navigate = useNavigate();
  const { productId = '' }: PageParams = useParams();
  const isAuthenticated = useIsAuthenticated();
  const [referralCardHidden, dismissReferralCard] = useReferralCardDismiss();

  const [isBoostsCardDismissed, setIsBoostsCardDismissed] = useDismissed(
    PersistKey.BOOSTS_CARD_LAST_DISMISSED,
    Number.MAX_VALUE,
  );

  const isVerified = useIsVerifiedAccount();
  const bankAccount = useGetActiveBankAccount();
  const { voyagerProductId, selectVoyagerProduct } = useAuthenticatedNav();
  const isMultiPortfolioEnabled = useFeatureFlag(
    FeatureFlagKeys.MULTI_PORTFOLIO_ENABLED,
  );

  const resp = useQuery<
    WebAppVoyagerDashboard,
    WebAppVoyagerDashboardVariables
  >(
    gql`
      query WebAppVoyagerDashboard(
        $tmdManuallyApprovedIndexCardDismissibleId: String!
        $tmdManuallyApprovedUniverseCardDismissibleId: String!
        $tmdManuallyApprovedEarthCardDismissibleId: String!
        $tmdManuallyApprovedGalaxyCardDismissibleId: String!
        $tmdManuallyApprovedExplorerCardDismissibleId: String!
      ) {
        contact {
          id
          firstName
          preferredName
          phoneNumberVerified
          account {
            id
            saverProductInstances {
              id
              primary
              portfolio
              portfolioPerformance {
                ...voyagerPortfolioPerformanceFields
              }
              boostRecipes {
                id
                name
                iconName
                groups {
                  id
                  status
                  audAmount
                  cancellableUntil
                }
              }
              upcomingSchedule {
                id
                frequency
                audAmount
                nextDue
              }
              investments {
                id
                summary {
                  id
                  ...investmentSummaryScalars
                }
                transactions(limit: 5) {
                  id
                  audAmount
                  requestedAt
                  status
                  unitExchange {
                    id
                    ...unitExchangeScalars
                    unitPrice {
                      id
                      ...unitPriceScalars
                    }
                  }
                  ... on Referral {
                    isReferrer
                    referralShortName
                    referrerShortName
                  }
                  ... on Redemption {
                    redemptionBankAccount: bankAccount {
                      id
                      ...bankAccountScalars
                    }
                  }
                  ... on Application {
                    estimatedExecutionDate
                    cancelDeadline
                    schedule {
                      id
                      ...scheduleScalars
                    }
                    applicationBankAccount: bankAccount {
                      id
                      ...bankAccountScalars
                    }
                  }
                  ... on Boost {
                    estimatedExecutionDate
                    itemGroup {
                      id
                      recipe {
                        id
                        name
                      }
                    }
                    boostBankAccount: bankAccount {
                      id
                      ...bankAccountScalars
                    }
                  }
                  ... on AccountFee {
                    type
                  }
                }
              }
              dailyBalances {
                date
                audAmount
              }
            }
            saverReferralShareDetails {
              id
              status
            }
            ...voyagerReferralsCardFields
          }
          ...webAppVoyagerBoostsCard
        }
        articles {
          id
          ...webAppArticleCardFragment
        }
        ...ShowTmdManuallyApprovedCard_QueryFragment
      }
      ${investmentSummaryScalars}
      ${bankAccountScalars}
      ${unitExchangeScalars}
      ${unitPriceScalars}
      ${scheduleScalars}
      ${articleCardFragment}
      ${voyagerReferralsCardScalars}
      ${voyagerBoostsCardFragment}
      ${saverTmdDetailsScalars}
      ${ShowTmdManuallyApprovedCard_QueryFragment}
      ${voyagerPortfolioPerformanceScalars}
    `,
    {
      skip: !isAuthenticated,
      variables: {
        tmdManuallyApprovedIndexCardDismissibleId:
          DynamicConfigDismissibleId.TMD_MANUALLY_APPROVED_INDEX_CARD,
        tmdManuallyApprovedUniverseCardDismissibleId:
          DynamicConfigDismissibleId.TMD_MANUALLY_APPROVED_UNIVERSE_CARD,
        tmdManuallyApprovedEarthCardDismissibleId:
          DynamicConfigDismissibleId.TMD_MANUALLY_APPROVED_EARTH_CARD,
        tmdManuallyApprovedGalaxyCardDismissibleId:
          DynamicConfigDismissibleId.TMD_MANUALLY_APPROVED_GALAXY_CARD,
        tmdManuallyApprovedExplorerCardDismissibleId:
          DynamicConfigDismissibleId.TMD_MANUALLY_APPROVED_EXPLORER_CARD,
      },
      onCompleted: (data) => {
        const account = data.contact.account;
        const products = account?.saverProductInstances;

        if (!product && products) {
          // Find default portfolio to display
          // Most recently TMD approved portfolio (within last 7 days)
          // otherwise, default to primary portfolio
          const tmdApprovedProduct = products.reduce(
            (selectedProduct, product) => {
              const portfolio = product.portfolio;

              let reviewedAt: string | null;
              switch (portfolio) {
                case SaverPortfolio.INDEX: {
                  reviewedAt = account.indexSaverTMDDetails.reviewedAt;
                  break;
                }
                case SaverPortfolio.EARTH: {
                  reviewedAt = account.earthSaverTMDDetails.reviewedAt;
                  break;
                }
                case SaverPortfolio.UNIVERSE: {
                  reviewedAt = account.universeSaverTMDDetails.reviewedAt;
                  break;
                }
                case SaverPortfolio.GALAXY: {
                  reviewedAt = account.galaxySaverTMDDetails.reviewedAt;
                  break;
                }
                case SaverPortfolio.EXPLORER: {
                  reviewedAt = account.explorerSaverTMDDetails.reviewedAt;
                  break;
                }
              }

              const reviewedAtDate = reviewedAt
                ? new Date(reviewedAt)
                : undefined;

              if (
                getShowTmdManuallyApprovedCard(portfolio, data) &&
                reviewedAtDate &&
                (!selectedProduct ||
                  isAfter(reviewedAtDate, selectedProduct.reviewedAtDate))
              ) {
                return {
                  id: product.id,
                  reviewedAtDate,
                };
              }

              return selectedProduct;
            },
            undefined as
              | {
                  id: string;
                  reviewedAtDate: Date;
                }
              | undefined,
          );
          const primaryPortfolio =
            data.contact.account?.saverProductInstances?.find(
              (spi) => spi.primary,
            );

          if (tmdApprovedProduct) {
            selectVoyagerProduct(tmdApprovedProduct.id, {
              route: Routes.VOYAGER_DASHBOARD,
            });
          } else if (primaryPortfolio) {
            selectVoyagerProduct(primaryPortfolio.id, {
              route: Routes.VOYAGER_DASHBOARD,
            });
          }
        }
      },
    },
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const articles = resp.data?.articles;
  const account = resp.data?.contact?.account;
  const product = account?.saverProductInstances?.find(
    (productInstance) =>
      productInstance.id === productId ||
      productInstance.portfolio === productId.toUpperCase(),
  );
  const portfolioPerformance = product?.portfolioPerformance;

  useEffect(() => {
    if (productId && product?.id && productId !== product?.id) {
      navigate(`/voyager/${product?.id}`);
    }
  }, [navigate, product?.id, productId]);

  const { hasTodoItems, todoItems } = useTodo(productId);

  useEffect(() => {
    if (productId && voyagerProductId !== productId) {
      selectVoyagerProduct(productId);
    }
  }, [productId, selectVoyagerProduct, voyagerProductId]);

  const mfaRegistrationEnabled = useFeatureFlag(
    FeatureFlagKeys.MFA_REGISTRATION_LOGGED_IN_ENABLED,
  );

  if (!product?.portfolio) {
    return null;
  }

  const showTmdManuallyApprovedCard = getShowTmdManuallyApprovedCard(
    product.portfolio,
    resp.data,
  );
  const transactions = product?.investments?.transactions ?? [];

  const canInvest =
    isVerified &&
    bankAccount?.status !== BankAccountStatus.Enum.VERIFICATION_REQUIRED;

  const showReferralCard =
    !referralCardHidden &&
    account?.saverReferralShareDetails?.status ===
      SaverReferralShareStatus.ELIGIBLE;

  const numOfCards = hasTodoItems ? 3 : 2;

  const portfolios = resp.data?.contact.account?.saverProductInstances ?? [];
  const isAddPortfolioPossible =
    portfolios.length < Object.keys(voyagerPortfolios).length;

  const upcomingInvestments: Array<UpcomingInvestment> = [];

  if (product.upcomingSchedule?.nextDue) {
    upcomingInvestments.push({
      title: `${capitalizeWords(product.upcomingSchedule.frequency)} Plan`,
      requestedAt: new Date(product.upcomingSchedule.nextDue),
      amount: product.upcomingSchedule.audAmount,
      iconName: 'streamline-button-loop-arrow',
    });
  }

  product.boostRecipes?.forEach((recipe) =>
    recipe.groups?.forEach((recipeGroup) => {
      if (
        [BoostItemGroupStatus.LOCKED, BoostItemGroupStatus.UNLOCKED].includes(
          recipeGroup.status,
        )
      ) {
        upcomingInvestments.push({
          title: recipe.name,
          requestedAt: recipeGroup.cancellableUntil
            ? new Date(recipeGroup.cancellableUntil)
            : undefined,
          amount: recipeGroup.audAmount,
          iconName: recipe.iconName,
        });
      }
    }),
  );
  const hasBoosts = !!resp.data?.contact?.account?.saverBoostRecipes?.length;
  const isBoostsCardVisible = !hasBoosts && !isBoostsCardDismissed;

  return (
    <PageContainer>
      <Stack spaceY="md">
        <Box display="flex" justifyContent="space-between">
          <HeaderWave
            name={
              resp.data?.contact?.preferredName || resp.data?.contact?.firstName
            }
          />
          {canInvest && (
            <Button
              trackingProperties={{ name: 'voyager_dashboard_invest' }}
              variant="primary"
              size="sm"
              onClick={() => {
                navigate(`${Routes.VOYAGER_DEPOSIT}/${productId}`);
              }}
            >
              Invest
            </Button>
          )}
        </Box>

        {!resp.data?.contact.phoneNumberVerified && mfaRegistrationEnabled && (
          <MfaRegistrationCard />
        )}

        {showTmdManuallyApprovedCard ? (
          <PortfolioTmdManuallyApprovedCard
            portfolio={product.portfolio}
            productId={product.id}
          />
        ) : showReferralCard &&
          (account?.saverReferralShareDetails ||
            account?.saverReferralReceiveDetails) ? (
          <Visible isHidden={{ xs: true, lg: false }} displayValue="block">
            <ReferralCampaignCard
              onDismiss={dismissReferralCard}
              account={account}
            />
          </Visible>
        ) : isBoostsCardVisible ? (
          <Visible isHidden={{ xs: true, lg: false }} displayValue="block">
            <BoostsCard onDismiss={setIsBoostsCardDismissed} />
          </Visible>
        ) : null}

        <Columns spaceX="md" spaceY="md">
          {hasTodoItems && (
            <Columns.Column
              width={{
                xs: 1,
                lg: numOfCards > 2 ? 1 : 1 / 2,
                xl: 1 / numOfCards,
              }}
            >
              <Box height={{ xs: 'auto', xl: 350 }}>
                <TodoCard items={todoItems} />
              </Box>
            </Columns.Column>
          )}

          <Columns.Column
            width={{
              xs: 1,
              lg: 1 / 2,
              xl: 1 / numOfCards,
            }}
          >
            <Box height={{ xs: 'auto', lg: 350 }}>
              <BalanceCard
                productId={productId}
                portfolio={product.portfolio}
                summary={product?.investments?.summary}
                upcomingSchedule={product?.upcomingSchedule}
                dailyBalances={product?.dailyBalances}
              />
            </Box>
          </Columns.Column>

          <Columns.Column
            width={{
              xs: 1,
              lg: 1 / 2,
              xl: 1 / numOfCards,
            }}
          >
            <Box height={{ xs: 'auto', lg: 350 }}>
              <VoyagerUnitPriceCard
                portfolio={product.portfolio}
                portfolioPerformance={portfolioPerformance}
                infoRoute={`${Routes.VOYAGER_UNIT_PRICE}/${productId}`}
              />
            </Box>

            {isMultiPortfolioEnabled && isAddPortfolioPossible && (
              <Box display="flex" justifyContent="flex-end" marginTop="xxs">
                <Text color="indigo.070" variant={4} isBold={true}>
                  <RouterLink
                    to={Routes.PORTFOLIO_ADD}
                    trackingProperties={{
                      name: 'voyager_dashboard_page_add_portfolio',
                    }}
                  >
                    Add another portfolio &rsaquo;
                  </RouterLink>
                </Text>
              </Box>
            )}
          </Columns.Column>
        </Columns>

        {showReferralCard &&
        (account?.saverReferralShareDetails ||
          account?.saverReferralReceiveDetails) ? (
          <Visible isHidden={{ xs: false, lg: true }} displayValue="block">
            <ReferralCampaignCard
              onDismiss={dismissReferralCard}
              account={account}
            />
          </Visible>
        ) : isBoostsCardVisible ? (
          <Visible isHidden={{ xs: false, lg: true }} displayValue="block">
            <BoostsCard onDismiss={setIsBoostsCardDismissed} />
          </Visible>
        ) : null}

        <Columns spaceX="md" spaceY="md">
          <Columns.Column width={{ xs: 1, xl: 1 / 2 }}>
            <Box height="100%" display="flex" flexDirection="column">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="sm"
              >
                <Heading variant={4} isBold={true}>
                  Upcoming investments{' '}
                  {upcomingInvestments.length > 0 &&
                    `(${upcomingInvestments.length})`}
                </Heading>

                <Text color="indigo.070" variant={4} isBold={true}>
                  <RouterLink
                    to={`${Routes.VOYAGER_TRANSACTIONS}/${productId}`}
                    trackingProperties={{
                      name: 'voyager_dashboard_page_view_more_upcoming_investments',
                    }}
                  >
                    View more &rsaquo;
                  </RouterLink>
                </Text>
              </Box>

              <Box flex={1}>
                <UpcomingInvestmentsCard
                  productId={productId}
                  hasBoosts={
                    !!resp.data?.contact.account?.saverBoostRecipes?.length
                  }
                  upcomingInvestments={upcomingInvestments}
                  isLoading={resp.loading}
                />
              </Box>
            </Box>
          </Columns.Column>

          <Columns.Column width={{ xs: 1, xl: 1 / 2 }}>
            <Box height="100%" display="flex" flexDirection="column">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="sm"
              >
                <Heading variant={4} isBold={true}>
                  Recent transactions
                </Heading>
                <Text color="indigo.070" variant={4} isBold={true}>
                  <RouterLink
                    to={`${Routes.VOYAGER_TRANSACTIONS}/${productId}`}
                    trackingProperties={{
                      name: 'voyager_dashboard_page_view_more_transactions',
                    }}
                  >
                    View more &rsaquo;
                  </RouterLink>
                </Text>
              </Box>

              <Box flex={1}>
                <RecentTransactionsCard
                  transactions={transactions}
                  isLoading={resp.loading}
                />
              </Box>
            </Box>
          </Columns.Column>

          <Columns.Column width={1}>
            <Stack spaceY="sm">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Heading variant={4} isBold={true}>
                  Recent movers
                </Heading>
                <Text color="indigo.070" variant={4} isBold={true}>
                  <RouterLink
                    to={`${Routes.VOYAGER_PORTFOLIO}/${productId}`}
                    trackingProperties={{
                      name: 'voyager_dashboard_page_view_more_recent_movers',
                    }}
                  >
                    View more &rsaquo;
                  </RouterLink>
                </Text>
              </Box>
              <RecentMovers
                numToShow={6}
                productId={productId}
                portfolio={product.portfolio}
                singleRow={true}
              />
              <Text variant={4} color="neutral.080">
                All figures are YTD. Past performance is not a guide to, or
                reliable indicator of, future performance.
              </Text>
            </Stack>
          </Columns.Column>
        </Columns>

        {!!articles && (
          <Stack spaceY="sm">
            <Heading variant={4} isBold={true}>
              Today&apos;s news
            </Heading>
            <Articles
              articles={articles}
              portfolio={product.portfolio}
              productId={product.id}
              trackingProperties={{
                name: 'voyager_dashboard_instrument_article_button',
              }}
            />
          </Stack>
        )}
      </Stack>
    </PageContainer>
  );
};

const VoyagerCrossSellDashboard: React.FC<
  React.PropsWithChildren<RouteComponentProps<PageParams>>
> = () => {
  const isAuthenticated = useIsAuthenticated();

  const resp = useQuery<WebAppVoyagerCrossSellDashboard>(
    gql`
      query WebAppVoyagerCrossSellDashboard {
        contact {
          id
          firstName
          preferredName
        }
      }
    `,
    {
      skip: !isAuthenticated,
    },
  );

  return (
    <PageContainer>
      <Stack spaceY="md">
        <Box display="flex" justifyContent="space-between">
          <HeaderWave
            name={
              resp.data?.contact?.preferredName || resp.data?.contact?.firstName
            }
          />
        </Box>

        <Columns alignX="center">
          <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
            <VoyagerCrossSell />
          </Columns.Column>
        </Columns>
      </Stack>
    </PageContainer>
  );
};

export const VoyagerDashboard = withVoyagerTopNavigation()(Dashboard);
