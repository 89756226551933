import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps, useLocation } from '@reach/router';
import {
  useCanReadSaver,
  useCanReadSuper,
  useLogout,
} from '@spaceship-fspl/auth';
import { Box, Visible } from '@spaceship-fspl/components';
import { WebAppNav } from '@spaceship-fspl/graphql/src/__generated__/WebAppNav';
import {
  FeatherMoreVerticalIcon,
  StreamlineLogout1Icon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  boxShadow,
  match,
  transition,
  useBetweenMedia,
} from '@spaceship-fspl/styles';
import { AuthenticatedNavProvider } from 'contexts/authenticated-nav';
import { Routes } from 'pages/routes';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  MORE_MENU_NAV_ITEM_HEIGHT,
  MoreMenuNavItem,
  NavItem,
  NavItemButton,
  NavItemProps,
} from './nav-item';
import { useNavList } from './nav-list';
import { Product, ProductsNav } from './products';
import { VoyagerInvestNav } from './voyager-invest';

const AUTH_NAV_BAR_WIDTH_DESKTOP = 70;
export const AUTH_NAV_BAR_ZINDEX = 1000;
const AUTH_NAV_MORE_MENU_ZINDEX = AUTH_NAV_BAR_ZINDEX - 1;
const AUTH_NAV_MORE_MENU_OVERLAY_ZINDEX = AUTH_NAV_MORE_MENU_ZINDEX - 1;
const AUTH_NAV_VOYAGER_INVEST_NAV_ZINDEX = AUTH_NAV_BAR_ZINDEX - 1;
const AUTH_NAV_VOYAGER_INVEST_NAV_WIDTH = 270;
export const AUTH_VOYAGER_TOP_NAV_ZINDEX =
  AUTH_NAV_MORE_MENU_OVERLAY_ZINDEX - 1;
export const AUTH_NAV_BAR_HEIGHT_MOBILE = 64;

const MAX_MOBILE_MAIN_NAV_ITEMS = 3;

const SHARED_PRODUCT_ROUTES = [
  Routes.ACCOUNT_USER_DETAILS,
  Routes.ACCOUNT_SUPER_DETAILS,
  Routes.ACCOUNT_VOYAGER_DETAILS,
];

const getProductFromCurrentPath = (currentPath: string): Product => {
  switch (true) {
    case currentPath.startsWith(Routes.SUPER_DASHBOARD):
      return 'super';

    case currentPath.startsWith(Routes.ETF_DASHBOARD):
      return 'etf';

    case currentPath.startsWith(Routes.STOCKS_DASHBOARD):
      return 'stocks';

    case currentPath.startsWith(Routes.VOYAGER_DASHBOARD):
    default:
      return 'voyager';
  }
};

const Nav: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isMobileScreenSize = useBetweenMedia('xs', 'md');
  const logout = useLogout();
  const canReadSaver = useCanReadSaver();
  const canReadSuper = useCanReadSuper();
  const isSuperOnlyUser = !canReadSaver && canReadSuper;
  const location = useLocation();
  const currentPath = location.pathname;
  const isSharedPath = SHARED_PRODUCT_ROUTES.find((route) =>
    currentPath.includes(route),
  );
  const productFromCurrentPath = getProductFromCurrentPath(currentPath);

  const [activeProduct, setActiveProduct] = useState<Product>(
    isSuperOnlyUser ? 'super' : productFromCurrentPath,
  );
  const [showMoreItems, setShowMoreItems] = useState(false);
  const [showVoyagerInvestNav, setShowVoyagerInvestNav] = useState(false);
  const [showSwitchProductNav, setShowSwitchProductNav] = useState(false);
  const showSecondaryNav =
    (activeProduct === 'voyager' && showVoyagerInvestNav) ||
    showSwitchProductNav;

  const resp = useQuery<WebAppNav>(gql`
    query WebAppNav {
      contact {
        id
        account {
          id
          saverBoostRecipes {
            id
          }
        }
      }
    }
  `);

  const navItems = useNavList({
    activeProduct,
    isMobileScreenSize,
    isSwitchProductNavOpen: showSwitchProductNav,
    onClickSwitchProductNavItem: () => {
      setShowSwitchProductNav(!showSwitchProductNav);
    },
    isInvestNavOpen: showVoyagerInvestNav,
    onClickInvestNavItem: () => {
      setShowVoyagerInvestNav(
        isMobileScreenSize ? false : !showVoyagerInvestNav,
      );
    },
  });

  const mainMobileMenuItems = navItems.product
    .filter((item) => !item.showInDesktopOnly && !item.showInMoreMenuOnly)
    .slice(0, MAX_MOBILE_MAIN_NAV_ITEMS);
  const moreMenuItems = navItems.product
    .filter((item) => !item.showInDesktopOnly)
    .slice(mainMobileMenuItems.length);
  const totalMoreMenuItems = moreMenuItems.length + 1;
  const moreMenuInnerHeight =
    totalMoreMenuItems * MORE_MENU_NAV_ITEM_HEIGHT +
    AUTH_NAV_BAR_HEIGHT_MOBILE +
    32;
  const firstMoreMenuItemWithFeature = moreMenuItems.find(
    (item) => !!item.highlightFeature,
  );

  const handleNavItemClick = ({ label, onClick }: NavItemProps): void => {
    onClick?.();
    setShowMoreItems(false);

    if (label !== 'Invest' || activeProduct !== 'voyager') {
      setShowVoyagerInvestNav(false);
    }
    setShowSwitchProductNav(false);
  };

  useEffect(() => {
    if (!isSharedPath) {
      setActiveProduct(getProductFromCurrentPath(currentPath));
    }
  }, [currentPath, isSharedPath]);

  return (
    <StyledLayout>
      <StyledNavContainer
        width={{
          xs: '100%',
          md: showSecondaryNav
            ? AUTH_NAV_BAR_WIDTH_DESKTOP + AUTH_NAV_VOYAGER_INVEST_NAV_WIDTH
            : AUTH_NAV_BAR_WIDTH_DESKTOP,
        }}
        height={{ xs: AUTH_NAV_BAR_HEIGHT_MOBILE, md: '100vh' }}
        onMouseLeave={() => {
          if (!isMobileScreenSize) {
            setShowVoyagerInvestNav(false);
            setShowSwitchProductNav(false);
          }
        }}
      >
        <StyledNavBar
          as="nav"
          display="flex"
          flexDirection={{ xs: 'row', md: 'column' }}
          backgroundColor="neutral.000"
          boxShadow="sm"
          bottom={{ xs: 0, md: 'auto' }}
          left={0}
          position="fixed"
          height={{ xs: AUTH_NAV_BAR_HEIGHT_MOBILE, md: '100vh' }}
          width={{ xs: '100%', md: AUTH_NAV_BAR_WIDTH_DESKTOP }}
        >
          <Box
            display="flex"
            flexDirection={{ xs: 'row', md: 'column' }}
            flex={1}
          >
            {navItems.product.map((item) => {
              const isInMoreMenu = !!moreMenuItems.find(
                (moreMenuItem) => moreMenuItem.label === item.label,
              );

              return item.showInMobileOnly && isInMoreMenu ? null : (
                <Box
                  key={item.label}
                  display={{
                    xs:
                      item.showInDesktopOnly || isInMoreMenu ? 'none' : 'block',
                    md: item.showInMobileOnly ? 'none' : 'block',
                  }}
                  flex={{ xs: 1, md: 'none' }}
                >
                  <NavItem
                    {...item}
                    onClick={() => {
                      handleNavItemClick(item);
                    }}
                  />
                </Box>
              );
            })}

            <Box display={{ xs: 'block', md: 'none' }} flex={1}>
              <NavItemButton
                icon={<FeatherMoreVerticalIcon />}
                label="More"
                onClick={() => {
                  setShowMoreItems(!showMoreItems);
                }}
                highlightFeature={
                  firstMoreMenuItemWithFeature?.highlightFeature
                }
                dismissHighlightFeatureOnClick={
                  firstMoreMenuItemWithFeature?.dismissHighlightFeatureOnClick
                }
              />
            </Box>
          </Box>

          <Visible component="div" isHidden={{ xs: true, md: false }}>
            <NavItem {...navItems.switchProduct} />

            <NavItem
              {...navItems.account}
              onClick={() => {
                handleNavItemClick(navItems.account);
              }}
            />
          </Visible>
        </StyledNavBar>

        <StyledSecondaryNavContainer show={showSecondaryNav}>
          {activeProduct === 'voyager' && showVoyagerInvestNav && (
            <VoyagerInvestNav
              hideMenu={() => {
                setShowVoyagerInvestNav(false);
              }}
              hasBoosts={
                !!resp.data?.contact.account?.saverBoostRecipes?.length
              }
            />
          )}

          {showSwitchProductNav && (
            <ProductsNav
              activeProduct={activeProduct}
              onSelectActiveProduct={(product) => {
                setActiveProduct(product);
                setShowSwitchProductNav(false);
              }}
            />
          )}
        </StyledSecondaryNavContainer>
      </StyledNavContainer>

      <StyledMoreMenuBox
        backgroundColor="neutral.000"
        borderRadius="md"
        boxShadow="md"
        display={{ md: 'none' }}
        paddingY="sm"
        position="fixed"
        bottom={showMoreItems ? '0px' : `-${moreMenuInnerHeight}px`}
        height={moreMenuInnerHeight}
        left={0}
        width="100%"
      >
        {moreMenuItems.map((item) => (
          <MoreMenuNavItem
            {...item}
            key={item.label}
            onClick={() => {
              handleNavItemClick(item);
            }}
          />
        ))}

        <MoreMenuNavItem
          route={Routes.LOGIN}
          label="Logout"
          icon={<StreamlineLogout1Icon />}
          onClick={() => {
            logout();
            setShowMoreItems(false);
          }}
        />
      </StyledMoreMenuBox>

      <StyledMain
        isMoreMenuVisible={showMoreItems}
        onClick={() => {
          setShowMoreItems(false);
        }}
      >
        {children}
      </StyledMain>
    </StyledLayout>
  );
};

export const AuthenticatedNav: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = ({ children }) => (
  <AuthenticatedNavProvider>
    <Nav>{children}</Nav>
  </AuthenticatedNavProvider>
);

const StyledLayout = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;

  ${match('md')`
    height: auto;
    overflow: visible;
    position: static;
  `}
`;

const StyledMain = styled.main<{ isMoreMenuVisible: boolean }>`
  box-sizing: border-box;
  height: calc(100vh - ${AUTH_NAV_BAR_HEIGHT_MOBILE}px);
  overflow-y: auto;

  ${({ isMoreMenuVisible }) =>
    isMoreMenuVisible
      ? css`
          ::after {
            ${backgroundColor('neutral.100')}
            content: '';
            display: block;
            height: 100vh;
            opacity: 0.5;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: ${AUTH_NAV_MORE_MENU_OVERLAY_ZINDEX};
          }
        `
      : ''}

  ${match('md')`
    height: auto;
    padding-left: ${AUTH_NAV_BAR_WIDTH_DESKTOP}px;
    overflow-y: visible;

    ::after {
      display: none;
    }
  `}
`;

const StyledNavContainer = styled(Box).attrs({
  position: 'fixed',
  bottom: { xs: 0, md: 'auto' },
  left: 0,
})`
  z-index: ${AUTH_NAV_BAR_ZINDEX};

  ${match('md')`
    ${transition}
  `}
`;

const StyledNavBar = styled(Box)`
  z-index: ${AUTH_NAV_BAR_ZINDEX};
`;

const StyledMoreMenuBox = styled(Box)`
  ${transition}
  z-index: ${AUTH_NAV_MORE_MENU_ZINDEX};
`;

const StyledSecondaryNavContainer = styled(Box).attrs({
  backgroundColor: 'neutral.000',
  paddingX: 'sm',
  paddingY: 'lg',
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  width: { xs: '100%', md: AUTH_NAV_VOYAGER_INVEST_NAV_WIDTH },
})<{ show: boolean }>`
  z-index: ${AUTH_NAV_VOYAGER_INVEST_NAV_ZINDEX};
  opacity: ${({ show }) => (show ? 1 : 0)};

  ${match('md')`
    ${transition}
    ${boxShadow('md')}
  `}
`;
