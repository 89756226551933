import { navigate } from '@reach/router';
import { useCanReadSaver, useCanReadSuper } from '@spaceship-fspl/auth';
import { useTreatment } from '@spaceship-fspl/experimentation';
import { useSuperPortfolioFlags } from '@spaceship-fspl/graphql';
import {
  AnalyticsLineGraphIcon,
  BriefcaseIcon,
  DollarCircleIcon,
  EtfProductIcon,
  FeatherGiftIcon,
  FeatherHomeIcon,
  FeatherLayersIcon,
  FeatherUserIcon,
  SpaceshipIcon,
  StocksProductIcon,
  StreamlineAstronomyTelescopeStarsIcon,
  StreamlineCommonFileRotateIcon,
  StreamlineMultipleNeutral2Icon,
  StreamlineSatellite1Icon,
  SuperProductIcon,
  VoyagerProductIcon,
} from '@spaceship-fspl/icons-web';
import { useUserAccess } from '@spaceship-fspl/super';
import { useGetMember as useGetSuperMember } from '@spaceship-fspl/super';
import { useTrack } from '@spaceship-fspl/tracking';
import { useAuthenticatedNav } from 'contexts/authenticated-nav';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { ExperimentNames } from 'helpers/experimentation';
import { useIsEligibleForSuperReferrals } from 'helpers/hooks/use-is-eligible-for-super-referrals';
import { Routes } from 'pages/routes';
import React from 'react';

import { NavItemProps } from './nav-item';
import { Product } from './products';

const ACCOUNT_NAV_ITEM = {
  route: Routes.ACCOUNT_USER_DETAILS,
  path: '/account/*',
  icon: <FeatherUserIcon />,
  label: 'Account',
  showInDesktopOnly: true,
};

const SUPER_CONSOLIDATE_NAV_ITEM = {
  route: Routes.SUPER_CONSOLIDATE,
  path: '/super/consolidate/*',
  icon: <FeatherLayersIcon />,
  label: 'Consolidate',
  ariaLabel: 'Super consolidate',
};

const SUPER_REFERRALS_NAV_ITEM = {
  route: Routes.SUPER_REFERRAL_SHARE_CODE,
  path: '/super/referrals/*',
  icon: <StreamlineMultipleNeutral2Icon />,
  label: 'Share Spaceship Super',
  ariaLabel: 'Share Spaceship Super',
};

export interface UseNavListProps {
  activeProduct: Product;
  isMobileScreenSize: boolean;
  isSwitchProductNavOpen: boolean;
  onClickSwitchProductNavItem: () => void;
  isInvestNavOpen: boolean;
  onClickInvestNavItem: () => void;
}

export interface UseNavListResults {
  product: Array<NavItemProps>;
  switchProduct: NavItemProps;
  account: NavItemProps;
}

export const useNavList = ({
  activeProduct,
  isInvestNavOpen,
  isMobileScreenSize,
  isSwitchProductNavOpen,
  onClickSwitchProductNavItem,
  onClickInvestNavItem,
}: UseNavListProps): UseNavListResults => {
  const track = useTrack();

  const { voyagerProductId } = useAuthenticatedNav();
  const canReadSaver = useCanReadSaver();
  const canReadSuper = useCanReadSuper();
  const { canSuperMatch } = useUserAccess();
  const canReferSuper = useIsEligibleForSuperReferrals();
  const { error: superMemberError, isLoading: superMemberIsLoading } =
    useGetSuperMember();

  const isSuperCrossSellEnabled =
    useFeatureFlag(FeatureFlagKeys.SUPER_CROSS_SELL) && !canReadSuper;
  const isVoyagerCrossSellEnabled =
    useFeatureFlag(FeatureFlagKeys.VOYAGER_CROSS_SELL) && !canReadSaver;
  const isSuperMatchEnabled = useFeatureFlag(
    FeatureFlagKeys.SUPERMATCH_ENABLED,
  );
  const isSuperFhssEnabled = useFeatureFlag(FeatureFlagKeys.FHSS_ENABLED);

  const supermatchDeeplinkTreatment = useTreatment(
    ExperimentNames.SUPERMATCH_DEEPLINK,
  );

  const switchProductNavItem = {
    icon: <StreamlineCommonFileRotateIcon />,
    label: 'Switch products',
    onClick: onClickSwitchProductNavItem,
    isActive: isSwitchProductNavOpen,
  };
  const mobileSwitchToVoyagerNavItem = {
    route: Routes.VOYAGER_DASHBOARD,
    path: '/voyager',
    icon: <VoyagerProductIcon />,
    label: isVoyagerCrossSellEnabled
      ? 'Explore Spaceship Voyager'
      : 'Switch to Voyager',
    tooltip: isVoyagerCrossSellEnabled
      ? 'Explore Spaceship Voyager'
      : 'Switch to Voyager',
    ariaLabel: isVoyagerCrossSellEnabled
      ? 'Explore Spaceship Voyager'
      : 'Switch to Voyager',
    showInMobileOnly: true,
    showInMoreMenuOnly: true,
  };
  const mobileSwitchToSuperNavItem = {
    route: Routes.SUPER_DASHBOARD,
    path: '/super',
    icon: <SuperProductIcon />,
    label: isSuperCrossSellEnabled
      ? 'Explore Spaceship Super'
      : 'Switch to Super',
    tooltip: isSuperCrossSellEnabled
      ? 'Explore Spaceship Super'
      : 'Switch to Super',
    ariaLabel: isSuperCrossSellEnabled
      ? 'Explore Spaceship Super'
      : 'Switch to Super',
    showInMobileOnly: true,
    showInMoreMenuOnly: true,
  };
  const mobileSwitchToEtfsNavItem = {
    route: Routes.ETF_DASHBOARD,
    path: '/etfs',
    icon: <EtfProductIcon />,
    label: 'Switch to ETFs',
    tooltip: 'Switch to ETFs',
    ariaLabel: 'Switch to ETFs',
    showInMobileOnly: true,
    showInMoreMenuOnly: true,
  };
  const mobileSwitchToStocksNavItem = {
    route: Routes.STOCKS_DASHBOARD,
    path: '/stocks',
    icon: <StocksProductIcon />,
    label: 'Switch to Stocks',
    tooltip: 'Switch to Stocks',
    ariaLabel: 'Switch to Stocks',
    showInMobileOnly: true,
    showInMoreMenuOnly: true,
  };

  const onSuperReferralsNavigationClick = (): void => {
    track?.(MarketingTrackingEvents.SUPER_REFERRALS_NAVIGATION_CLICK);
  };
  const superFlagsResp = useSuperPortfolioFlags();
  const sftLimitedServicePeriodEnabled =
    superFlagsResp.data?.superPortfolioFlags?.sftLimitedServicePeriodEnabled;

  switch (activeProduct) {
    case 'super': {
      const mobileSwitchProducts = [
        mobileSwitchToVoyagerNavItem,
        mobileSwitchToEtfsNavItem,
        mobileSwitchToStocksNavItem,
      ];

      if (!canReadSuper) {
        return {
          product: [
            {
              route: Routes.SUPER_DASHBOARD,
              path: '/super',
              icon: <SpaceshipIcon />,
              label: 'Super',
              tooltip: 'Dashboard',
              ariaLabel: 'Super dashboard',
              showLabelInDesktop: true,
            },
            {
              ...ACCOUNT_NAV_ITEM,
              showInMobileOnly: true,
              showInDesktopOnly: false,
            },
            ...mobileSwitchProducts,
          ],
          switchProduct: switchProductNavItem,
          account: ACCOUNT_NAV_ITEM,
        };
      }

      const superNavItems: Array<NavItemProps> = [
        {
          route: Routes.SUPER_DASHBOARD,
          path: '/super',
          icon: <SpaceshipIcon />,
          label: 'Super',
          tooltip: 'Dashboard',
          ariaLabel: 'Super dashboard',
          showLabelInDesktop: true,
        },
      ];

      // If the super member call errors (e.g. external super API is down), then don't show the subsequent Super nav
      // items - the above Super Dashboard component will show a nice full page error message.
      if (superMemberIsLoading || !superMemberError) {
        superNavItems.push({
          route: Routes.SUPER_TRANSACTIONS,
          path: '/super/transactions/*',
          icon: <AnalyticsLineGraphIcon />,
          label: 'Transactions',
          ariaLabel: 'Super transactions',
        });

        superNavItems.push({
          ...ACCOUNT_NAV_ITEM,
          showInMobileOnly: true,
          showInDesktopOnly: false,
        });

        if (canReferSuper && !sftLimitedServicePeriodEnabled) {
          superNavItems.push({
            ...SUPER_REFERRALS_NAV_ITEM,
            showInMobileOnly: true,
            onClick: onSuperReferralsNavigationClick,
          });
        }

        if (!sftLimitedServicePeriodEnabled) {
          superNavItems.push({
            route: Routes.SUPER_CONTRIBUTIONS,
            path: '/super/contributions/*',
            icon: <DollarCircleIcon />,
            label: 'Contribute',
            ariaLabel: 'Super contribute',
          });
        }

        if (canSuperMatch && !sftLimitedServicePeriodEnabled) {
          if (isSuperMatchEnabled) {
            superNavItems.push({
              ...SUPER_CONSOLIDATE_NAV_ITEM,
              route: Routes.SUPER_SUPERMATCH,
            });
          } else {
            superNavItems.push({
              ...SUPER_CONSOLIDATE_NAV_ITEM,
              onClick: async () => {
                await supermatchDeeplinkTreatment.activate();

                if (supermatchDeeplinkTreatment.name === 'variation') {
                  navigate(Routes.SUPER_COMING_SOON_CONSOLIDATE);
                }
              },
            });
          }
        }

        if (canReferSuper && !sftLimitedServicePeriodEnabled) {
          superNavItems.push({
            ...SUPER_REFERRALS_NAV_ITEM,
            showInDesktopOnly: true,
            onClick: onSuperReferralsNavigationClick,
          });
        }

        if (isSuperFhssEnabled && !sftLimitedServicePeriodEnabled) {
          superNavItems.push({
            route: Routes.SUPER_FHSS,
            path: Routes.SUPER_FHSS,
            icon: <FeatherHomeIcon />,
            label: 'First Home Super Saver',
            ariaLabel: 'First Home Super Saver',
          });
        }

        if (isVoyagerCrossSellEnabled) {
          superNavItems.push({
            route: Routes.ACCOUNT_VOYAGER_DETAILS,
            path: '',
            icon: <StreamlineSatellite1Icon />,
            label: 'Explore Spaceship Voyager',
            ariaLabel: 'Explore Spaceship Voyager',
            showInDesktopOnly: true,
          });
        }
      }

      superNavItems.push(...mobileSwitchProducts);

      return {
        product: superNavItems,
        switchProduct: switchProductNavItem,
        account: ACCOUNT_NAV_ITEM,
      };
    }

    case 'etf': {
      return {
        product: [
          {
            route: Routes.ETF_DASHBOARD,
            path: Routes.ETF_DASHBOARD,
            icon: <SpaceshipIcon />,
            label: 'ETFs',
            tooltip: 'Dashboard',
            ariaLabel: 'ETFs dashboard',
            showLabelInDesktop: true,
          },
          {
            ...ACCOUNT_NAV_ITEM,
            showInMobileOnly: true,
            showInDesktopOnly: false,
          },
          mobileSwitchToVoyagerNavItem,
          mobileSwitchToSuperNavItem,
          mobileSwitchToStocksNavItem,
        ],
        switchProduct: switchProductNavItem,
        account: ACCOUNT_NAV_ITEM,
      };
    }

    case 'stocks': {
      return {
        product: [
          {
            route: Routes.STOCKS_DASHBOARD,
            path: Routes.STOCKS_DASHBOARD,
            icon: <SpaceshipIcon />,
            label: 'Stocks',
            tooltip: 'Dashboard',
            ariaLabel: 'Stocks dashboard',
            showLabelInDesktop: true,
          },
          {
            ...ACCOUNT_NAV_ITEM,
            showInMobileOnly: true,
            showInDesktopOnly: false,
          },
          mobileSwitchToVoyagerNavItem,
          mobileSwitchToSuperNavItem,
          mobileSwitchToEtfsNavItem,
        ],
        switchProduct: switchProductNavItem,
        account: ACCOUNT_NAV_ITEM,
      };
    }

    case 'voyager':
    default: {
      const mobileSwitchProducts = [
        mobileSwitchToSuperNavItem,
        mobileSwitchToEtfsNavItem,
        mobileSwitchToStocksNavItem,
      ];

      if (!canReadSaver) {
        return {
          product: [
            {
              route: Routes.VOYAGER_DASHBOARD,
              path: '/voyager',
              icon: <SpaceshipIcon />,
              label: 'Voyager',
              tooltip: 'Dashboard',
              ariaLabel: 'Voyager dashboard',
              showLabelInDesktop: true,
            },
            {
              ...ACCOUNT_NAV_ITEM,
              showInMobileOnly: true,
              showInDesktopOnly: false,
            },
            ...mobileSwitchProducts,
          ],
          switchProduct: switchProductNavItem,
          account: ACCOUNT_NAV_ITEM,
        };
      }

      const voyagerNavItems: Array<NavItemProps> = [
        {
          route: Routes.VOYAGER_DASHBOARD,
          path: '/voyager/:productId',
          icon: <SpaceshipIcon />,
          label: 'Voyager',
          tooltip: 'Dashboard',
          ariaLabel: 'Voyager dashboard',
          showLabelInDesktop: true,
        },
      ];

      voyagerNavItems.push({
        route: isMobileScreenSize ? Routes.VOYAGER_INVEST_OPTIONS : undefined,
        path: '/voyager/invest/*',
        icon: <DollarCircleIcon />,
        label: 'Invest',
        ariaLabel: 'Voyager invest',
        onClick: onClickInvestNavItem,
        isActive: isInvestNavOpen || undefined,
      });

      voyagerNavItems.push(
        {
          route: Routes.VOYAGER_PORTFOLIO,
          path: '/voyager/portfolio/:productId/*',
          icon: <BriefcaseIcon />,
          label: 'Portfolio',
          ariaLabel: 'Voyager portfolio',
        },
        {
          route: Routes.VOYAGER_TRANSACTIONS,
          path: '/voyager/transactions/:productId',
          icon: <AnalyticsLineGraphIcon />,
          label: 'Transactions',
          ariaLabel: 'Voyager transactions',
        },
        {
          ...ACCOUNT_NAV_ITEM,
          highlightFeature: FeatureFlagKeys.MULTI_PORTFOLIO_ENABLED,
          showInMobileOnly: true,
          showInDesktopOnly: false,
        },
        {
          route: Routes.VOYAGER_REFERRALS,
          path: '/voyager/referrals/*',
          icon: <FeatherGiftIcon />,
          label: 'Refer',
          ariaLabel: 'Voyager referrals',
          showInDesktopOnly: true,
        },
      );

      if (isSuperCrossSellEnabled) {
        voyagerNavItems.push({
          route: Routes.ACCOUNT_SUPER_DETAILS,
          path: '',
          icon: <StreamlineAstronomyTelescopeStarsIcon />,
          label: 'Explore Spaceship Super',
          ariaLabel: 'Explore Spaceship Super',
          showInDesktopOnly: true,
        });
      }

      voyagerNavItems.push(...mobileSwitchProducts);

      return {
        product: voyagerNavItems.map((item) => {
          const hasProductId =
            /:productId/g.test(item.path ?? '') && !!voyagerProductId;
          return {
            ...item,
            route:
              hasProductId && item.route
                ? `${item.route}/${voyagerProductId}`
                : item.route,
            path: hasProductId
              ? item.path?.replace(':productId', voyagerProductId)
              : item.path,
          };
        }),
        switchProduct: switchProductNavItem,
        account: {
          ...ACCOUNT_NAV_ITEM,
          highlightFeature: FeatureFlagKeys.MULTI_PORTFOLIO_ENABLED,
        },
      };
    }
  }
};
