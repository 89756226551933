import {
  Box,
  Stack,
  Text,
  UnstyledButton,
  UnstyledLink,
} from '@spaceship-fspl/components';
import {
  FeatherArrowRightIcon,
  FeatherArrowUpRightIcon,
} from '@spaceship-fspl/icons-web';
import {
  borderBottomColor,
  borderWidthBottom,
  Color,
  color,
  TextVariant,
  transition,
} from '@spaceship-fspl/styles';
import { useGetDocumentDetails } from '@spaceship-fspl/super';
import { useTrack } from '@spaceship-fspl/tracking';
import { useNotifications } from 'contexts/notifications';
import { compareAsc } from 'date-fns';
import { TrackingEvent } from 'helpers/analytics';
import { GENERIC_ERROR_MESSAGE } from 'helpers/errors';
import { addRumError } from 'helpers/monitoring';
import React from 'react';
import styled from 'styled-components';

interface ResourceListStyleProps {
  isBold?: boolean;
  textVariant?: TextVariant;
}

export interface ResourceListProps extends ResourceListStyleProps {
  resources: Array<ResourceItemProps>;
  isSuperResource?: boolean;
}

export const ResourceList: React.FC<
  React.PropsWithChildren<ResourceListProps>
> = ({
  resources,
  isSuperResource = false,
  isBold = false,
  textVariant = 2,
}) => {
  return (
    <StyledResourceList>
      {resources.map(({ isSuperResource: itemIsSuperResource, ...item }) => (
        <StyledResourceListItem key={item.memberDocumentId || item.label}>
          <ResourceItem
            isBold={isBold}
            textVariant={textVariant}
            isSuperResource={itemIsSuperResource ?? isSuperResource}
            {...item}
          />
        </StyledResourceListItem>
      ))}
    </StyledResourceList>
  );
};

export interface ResourceItemProps {
  label: string;
  onClick?: () => void;
  subText?: string;
  link?: string;
  icon?: React.ReactNode;
  indicateWillOpenNewTab?: boolean;
  isSuperResource?: boolean;
  isReadOnly?: boolean;
  memberDocumentId?: string;
}

export const ResourceItem: React.FC<
  React.PropsWithChildren<ResourceItemProps & ResourceListStyleProps>
> = ({
  label,
  subText,
  onClick,
  link,
  indicateWillOpenNewTab = false,
  icon,
  isSuperResource = false,
  memberDocumentId,
  isBold = false,
  textVariant = 2,
  isReadOnly = false,
}) => {
  const track = useTrack();

  const { data, refetch } = useGetDocumentDetails(memberDocumentId);
  const { popToast } = useNotifications();

  const Icon = indicateWillOpenNewTab
    ? FeatherArrowUpRightIcon
    : FeatherArrowRightIcon;

  const iconColor: Color = indicateWillOpenNewTab
    ? 'neutral.100'
    : 'neutral.080';

  const trackDocument = (docType: string, docName: string): void => {
    track?.(TrackingEvent.CLICK, {
      properties: {
        name: `${docType}_${docName
          .toLowerCase()
          .replace(/[^\w\s]/g, '')
          .replace(/\s+/g, '_')}`,
      },
    });
  };

  const handleClick = async (): Promise<void> => {
    onClick?.();

    if (isSuperResource) {
      if (!memberDocumentId) {
        trackDocument('super_document', label);
      } else if (data) {
        const currentDateTime = new Date();
        const expiryDateTime = new Date(data.urlExpiry);

        if (compareAsc(expiryDateTime, currentDateTime) > 0) {
          window.open(data.url);
        } else {
          // refetch a new document url
          try {
            const response = await refetch();
            window.open(response.data?.url);
          } catch (error) {
            addRumError({
              error: new Error(
                `failed to get super member ${data?.type} document`,
              ),
              context: {
                cause: error,
              },
            });
            popToast({
              message: GENERIC_ERROR_MESSAGE,
              level: 'error',
            });
          }
        }
        trackDocument('member_document', data.type);
      }
    } else {
      track?.(TrackingEvent.CLICK, {
        properties: {
          name: label.toLowerCase().replace(/\s/g, '_'),
        },
      });
    }
  };

  if (isSuperResource && memberDocumentId && !data) {
    return null;
  }

  const item = (
    <Box paddingY="xs" display="flex" justifyContent="space-between">
      <Stack>
        <StyledResourceItemText
          variant={textVariant}
          isBold={isBold}
          component="span"
        >
          {data?.type || label}
        </StyledResourceItemText>
        {subText && (
          <StyledResourceItemText
            variant={textVariant}
            component="span"
            color="neutral.080"
          >
            {subText}
          </StyledResourceItemText>
        )}
      </Stack>
      <Box paddingLeft={{ xs: 'xxs', md: 'sm' }}>
        {icon || (
          <StyledResourceItemIcon color={iconColor}>
            <Icon size="md" />
          </StyledResourceItemIcon>
        )}
      </Box>
    </Box>
  );

  return (
    <StyledResourceWrapper>
      {link ? (
        <UnstyledLink
          enabled={!isReadOnly}
          href={link}
          target="_blank"
          rel="noreferrer"
          onClick={handleClick}
        >
          {item}
        </UnstyledLink>
      ) : onClick || memberDocumentId ? (
        <UnstyledButton isDisabled={isReadOnly} onClick={handleClick}>
          {item}
        </UnstyledButton>
      ) : (
        item
      )}
    </StyledResourceWrapper>
  );
};

const StyledResourceItemText = styled(Text)``;

const StyledResourceItemIcon = styled.div<{ color: Color }>`
  ${(props) => color(props.color)}
`;

const StyledResourceListItem = styled.li`
  :not(:last-child, :only-child) {
    ${borderWidthBottom('sm')}
    ${borderBottomColor('neutral.050')}
    border-bottom-style: solid;
  }
`;

const StyledResourceList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const StyledResourceWrapper = styled.div`
  ${transition}
  text-align: left;
  width: 100%;

  a,
  a:visited,
  button {
    width: 100%;

    &,
    ${StyledResourceItemText} {
      ${color('neutral.100')}
    }

    :hover,
    :hover ${StyledResourceItemText}, :hover ${StyledResourceItemIcon} {
      ${color('indigo.070')}
      text-decoration: none;
    }
  }
`;
