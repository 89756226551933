import { Match, useMatch } from '@reach/router';
import { Box, Heading, Stack, Text, Visible } from '@spaceship-fspl/components';
import {
  BoostsIcon,
  DollarCircleIcon,
  FeatherChevronRightIcon,
  IconProps,
  StreamlineButtonLoopArrowIcon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  boxShadow,
  match,
  minHeight,
  padding,
  transition,
} from '@spaceship-fspl/styles';
import { RouterLink } from 'components/router-link';
import { fromGlobalId } from 'graphql-relay';
import { Routes } from 'pages/routes';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
interface VoyagerInvestNavProps {
  hideMenu?: () => void;
  hasBoosts: boolean;
}

export const VoyagerInvestNav: React.FC<
  React.PropsWithChildren<VoyagerInvestNavProps>
> = ({ hideMenu, hasBoosts }) => {
  const match = useMatch(location.pathname);
  const productId = useMemo(() => {
    return match?.uri.split('/').find((part) => {
      if (part?.length === 76) {
        const { type } = fromGlobalId(part);
        return type === 'SaverProductInstance';
      }
      return false;
    });
  }, [match]);

  return (
    <Stack spaceY={{ xs: 'sm', md: 'md' }}>
      <Heading variant={4} isBold={true} align={{ md: 'center' }}>
        Invest in your portfolio
      </Heading>

      <Stack spaceY="sm">
        <Match path={`${Routes.VOYAGER_DEPOSIT}/*`}>
          {({ match }): JSX.Element => (
            <InvestRouterLink
              to={
                productId
                  ? `${Routes.VOYAGER_DEPOSIT}/${productId}`
                  : Routes.VOYAGER_DEPOSIT
              }
              onClick={hideMenu}
              trackingProperties={{
                name: 'authenticated_voyager_invest_nav_one_off_deposit',
              }}
              isActive={!!match}
            >
              <Option
                icon={DollarCircleIcon}
                title="One off investment"
                subtitle="Invest in your portfolio with as little as you like."
              />
            </InvestRouterLink>
          )}
        </Match>

        <Match path={`${Routes.VOYAGER_INVESTMENT_PLAN}/*`}>
          {({ match }): JSX.Element => (
            <InvestRouterLink
              to={Routes.VOYAGER_INVESTMENT_PLAN_PORTFOLIOS}
              onClick={hideMenu}
              trackingProperties={{
                name: 'authenticated_voyager_invest_nav_investment_plan',
              }}
              isActive={!!match}
            >
              <Option
                icon={StreamlineButtonLoopArrowIcon}
                title="Investment plan"
                subtitle="Automatic weekly, fortnightly or monthly investments."
              />
            </InvestRouterLink>
          )}
        </Match>

        <Match path={`${Routes.BOOSTS}/*`}>
          {({ match }): JSX.Element => (
            <InvestRouterLink
              to={
                hasBoosts ? Routes.BOOSTS_DASHBOARD_HOME : Routes.BOOSTS_INTRO
              }
              onClick={hideMenu}
              trackingProperties={{
                name: 'authenticated_voyager_invest_nav_boost',
              }}
              isActive={!!match}
            >
              <Option
                icon={BoostsIcon}
                title="Boost"
                subtitle="Boost your investment balance by making the most of everyday moments"
              />
            </InvestRouterLink>
          )}
        </Match>
      </Stack>
    </Stack>
  );
};

const Option: React.FC<
  React.PropsWithChildren<{
    icon: React.ComponentType<IconProps>;
    title: React.ReactNode;
    subtitle: React.ReactNode;
  }>
> = ({ icon: Icon, title, subtitle }) => {
  return (
    <Stack spaceY="xs">
      <Box display="flex" justifyContent="space-between">
        <Icon color="indigo.070" size="lg" />
        <Visible isHidden={{ md: true }}>
          <FeatherChevronRightIcon color="indigo.070" size="md" />
        </Visible>
      </Box>

      <Stack spaceY="xxs">
        <Heading variant={5} color="black.100">
          {title}
        </Heading>

        <Text variant={3} color="neutral.085">
          {subtitle}
        </Text>
      </Stack>
    </Stack>
  );
};

const InvestRouterLink = styled(RouterLink)<{ isActive: boolean }>`
  ${backgroundColor('neutral.000')}
  ${borderRadius('xxs')}
  ${borderColor('neutral.050')}
  ${borderWidth({ xs: 'none', md: 'md' })}
  ${boxShadow('sm')}
  ${minHeight({ xs: 120, md: 150 })}
  ${padding('md')}
  ${transition}
  border-style: solid;
  display: block;

  ${({ isActive }) =>
    isActive
      ? borderColor('indigo.070')
      : css`
          :hover {
            ${borderColor('indigo.020')}
          }
        `}

  ${match('md')`
    ${boxShadow('none')}
  `}
`;
