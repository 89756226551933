import {
  Box,
  ButtonVariant,
  Container,
  Heading,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  FeatherArrowLeftIcon,
  FeatherArrowRightIcon,
} from '@spaceship-fspl/icons-web';
import { Button, ButtonProps } from 'components/button';
import { IntercomFooter } from 'components/intercom-footer';
import { AUTH_NAV_BAR_HEIGHT_MOBILE } from 'components/navigation/authenticated';
import { AccessibilityLabel } from 'helpers/accessibility';
import React from 'react';

export const PageContainer: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Container>
    <Box
      paddingTop={{ xs: 'md', md: 'xl' }}
      paddingBottom={{ xs: 'lg', md: 'xxxl' }}
    >
      {children}
    </Box>

    <IntercomFooter />
  </Container>
);

export const CenterPageContainer: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    minHeight={{
      xs: `calc(100vh - ${AUTH_NAV_BAR_HEIGHT_MOBILE}px)`,
      md: '100vh',
    }}
    alignItems="center"
    justifyContent="center"
    paddingY={{ xs: 'md', md: 'xl' }}
  >
    <Container>{children}</Container>
    <IntercomFooter />
  </Box>
);

export interface PageHeadingProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
}

export const PageHeading: React.FC<
  React.PropsWithChildren<PageHeadingProps>
> = ({ title, subtitle }) => {
  return (
    <Stack spaceY="sm" alignX="center">
      <Heading variant={3} align="center" component="h1">
        {title}
      </Heading>

      {subtitle && (
        <Text variant={2} align="center">
          {subtitle}
        </Text>
      )}
    </Stack>
  );
};

export const PageFormButtonContainer: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Box marginTop="xl">
      <Stack spaceY="xs" alignX="center">
        {children}
      </Stack>
    </Box>
  );
};

export const PageFormContinueButton: React.FC<
  React.PropsWithChildren<Omit<ButtonProps, 'variant' | 'size'>>
> = ({ children, ...props }) => {
  return (
    <Button
      aria-label={AccessibilityLabel.CONTINUE}
      type="submit"
      variant="primary"
      size="lg"
      {...props}
    >
      {children || 'Continue'}
    </Button>
  );
};

export const PageFormSkipButton: React.FC<
  React.PropsWithChildren<Omit<ButtonProps, 'variant' | 'size'>>
> = (props) => {
  return (
    <Button
      aria-label={AccessibilityLabel.SKIP}
      variant="secondary"
      size="lg"
      type="button"
      {...props}
    >
      <Inline spaceX="xxs" alignX="center" alignY="center">
        Skip
        <FeatherArrowRightIcon size="md" />
      </Inline>
    </Button>
  );
};

export const PageFormGoBackButton: React.FC<
  React.PropsWithChildren<
    Omit<ButtonProps, 'variant' | 'size'> & { variant?: ButtonVariant }
  >
> = ({ variant = 'secondary', ...props }) => {
  return (
    <Button
      aria-label={AccessibilityLabel.GO_BACK}
      variant={variant}
      size="lg"
      type="button"
      {...props}
    >
      <Inline spaceX="xxs" alignX="center" alignY="center">
        <FeatherArrowLeftIcon size="md" />
        <p>Go Back</p>
      </Inline>
    </Button>
  );
};

export const PageFormCancelButton: React.FC<
  React.PropsWithChildren<Omit<ButtonProps, 'variant' | 'size'>>
> = ({ children, ...props }) => {
  return (
    <Button
      aria-label={AccessibilityLabel.CANCEL_BUTTON}
      variant="secondary"
      size="lg"
      type="button"
      {...props}
    >
      {children || 'Cancel'}
    </Button>
  );
};

export const PageFormExtraButton: React.FC<
  React.PropsWithChildren<Omit<ButtonProps, 'variant' | 'size'>>
> = ({ children, ...props }) => {
  return (
    <Button type="button" variant="secondary" size="lg" {...props}>
      {children}
    </Button>
  );
};
