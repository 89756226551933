import { gql, OperationVariables, QueryResult, useQuery } from '@apollo/client';
import { navigate } from '@reach/router';
import { useIsAuthenticated } from '@spaceship-fspl/auth';
import {
  fromProtoPortfolio,
  SaverTMDResult,
  toProtoPortfolio,
  useSaverTMDDetails,
} from '@spaceship-fspl/graphql';
import { SaverTMDDetails } from '@spaceship-fspl/graphql/src/__generated__/SaverTMDDetails';
import { WebAppAddNewPortfolioSelectionProvider } from '@spaceship-fspl/graphql/src/__generated__/WebAppAddNewPortfolioSelectionProvider';
import { useIsStatusVerified } from '@spaceship-fspl/green-id';
import { InternalRoutes } from '@spaceship-fspl/helpers';
import { IconComponent } from '@spaceship-fspl/icons-web';
import {
  GreenIdRuleSet,
  SaverPortfolio,
} from '@spaceship-fspl/types/externalapi';
import { tmdDetailsForPortfolio } from '@spaceship-fspl/voyager';
import { useOnboardingRequestContext } from 'contexts/saver/onboarding';
import { voyagerPortfolios } from 'helpers/portfolios';
import { Routes } from 'pages/routes';
import React, { createContext, useCallback, useContext, useMemo } from 'react';

import { usePortfolioRegistration } from './portfolio-registration';

type Portfolio = SaverPortfolio.Enum;

interface PortfolioSelection {
  variant: 'onboarding' | 'add';
  portfolio?: Portfolio;
  currentPortfolios?: Array<Portfolio>;
  navigateToPortfolioSelection: () => void;
  navigateToTMDForm: () => void;
  onPortfolioSelect: (value: Portfolio) => void;
  onTMDEligible: () => void;
  saverTMDDetails: QueryResult<SaverTMDDetails, OperationVariables>;
  tmdLink: string;
  pdsLink: string;
  tmdDetailsByPortfolio: Record<
    Portfolio,
    | {
        result: SaverTMDResult | null;
        submissionAvailable: boolean | null;
        resubmissionConfirmationRequired: boolean | null;
        reviewedAt: string | null;
      }
    | undefined
  >;
  portfolioIcon?: {
    default: IconComponent;
    stars: IconComponent;
  };
}

export const PortfolioSelectionContext = createContext<
  PortfolioSelection | undefined
>(undefined);

export const OnboardingPortfolioSelectionProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const [request, setRequest] = useOnboardingRequestContext();

  const portfolio = request.createSaverAccount?.portfolio || undefined;
  const portfolioEnum = fromProtoPortfolio(Number(portfolio));
  const selectedPortfolio = portfolioEnum
    ? voyagerPortfolios[portfolioEnum]
    : undefined;
  const pdsLink = selectedPortfolio?.pds ?? InternalRoutes.IMPORTANT_DOCUMENTS;
  const tmdLink = selectedPortfolio?.tmd ?? InternalRoutes.IMPORTANT_DOCUMENTS;
  const portfolioIcon = selectedPortfolio?.icon;
  const saverTMDDetails = useSaverTMDDetails();

  const onTMDEligible: () => void = useCallback(() => {
    navigate(Routes.VOYAGER_ONBOARDING_TAX_RESIDENCY);
  }, []);

  const onPortfolioSelect = useCallback(
    async (portfolio: Portfolio) => {
      setRequest({
        ...request,
        createSaverAccount: {
          ...request.createSaverAccount,
          portfolio,
        },
      });
    },
    [request, setRequest],
  );

  const tmdDetailsByPortfolio = {
    [SaverPortfolio.Enum.UNKNOWN]: undefined,
    [SaverPortfolio.Enum.INDEX]: tmdDetailsForPortfolio(
      SaverPortfolio.Enum.INDEX,
      saverTMDDetails?.data?.contact,
    ),
    [SaverPortfolio.Enum.UNIVERSE]: tmdDetailsForPortfolio(
      SaverPortfolio.Enum.UNIVERSE,
      saverTMDDetails?.data?.contact,
    ),
    [SaverPortfolio.Enum.EARTH]: tmdDetailsForPortfolio(
      SaverPortfolio.Enum.EARTH,
      saverTMDDetails?.data?.contact,
    ),
    [SaverPortfolio.Enum.GALAXY]: tmdDetailsForPortfolio(
      SaverPortfolio.Enum.GALAXY,
      saverTMDDetails?.data?.contact,
    ),
    [SaverPortfolio.Enum.EXPLORER]: tmdDetailsForPortfolio(
      SaverPortfolio.Enum.EXPLORER,
      saverTMDDetails?.data?.contact,
    ),
  };

  return (
    <PortfolioSelectionContext.Provider
      value={{
        variant: 'onboarding',
        portfolio: request.createSaverAccount?.portfolio || undefined,
        navigateToPortfolioSelection: () => {
          navigate(Routes.VOYAGER_ONBOARDING_PORTFOLIO);
        },
        navigateToTMDForm: () => {
          navigate(Routes.VOYAGER_ONBOARDING_TMD_FORM);
        },
        tmdDetailsByPortfolio,
        onPortfolioSelect,
        onTMDEligible,
        saverTMDDetails,
        tmdLink,
        pdsLink,
        portfolioIcon,
      }}
    >
      {children}
    </PortfolioSelectionContext.Provider>
  );
};

export const AddNewPortfolioSelectionProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { portfolio, setPortfolio } = usePortfolioRegistration();

  const portfolioEnum = fromProtoPortfolio(Number(portfolio));
  const selectedPortfolio = portfolioEnum
    ? voyagerPortfolios[portfolioEnum]
    : undefined;
  const pdsLink = selectedPortfolio?.pds ?? InternalRoutes.IMPORTANT_DOCUMENTS;
  const tmdLink = selectedPortfolio?.tmd ?? InternalRoutes.IMPORTANT_DOCUMENTS;
  const portfolioIcon = selectedPortfolio?.icon;

  const saverTMDDetails = useSaverTMDDetails();

  const resp = useQuery<WebAppAddNewPortfolioSelectionProvider>(
    gql`
      query WebAppAddNewPortfolioSelectionProvider {
        contact {
          id
          account {
            id
            saverProductInstances {
              id
              portfolio
            }
          }
        }
      }
    `,
    {
      skip: !isAuthenticated,
    },
  );
  const currentPortfolios = useMemo(
    () =>
      resp.data?.contact?.account?.saverProductInstances?.map((pi) =>
        toProtoPortfolio(pi.portfolio),
      ) ?? [],
    [resp.data?.contact?.account?.saverProductInstances],
  );

  const isVerifiedUser = useIsStatusVerified(
    GreenIdRuleSet.Enum.VOYAGER_ONBOARDING,
  );

  const onTMDEligible: () => void = () => {
    navigate(
      isVerifiedUser
        ? Routes.PORTFOLIO_ADD_INVEST_DEPOSIT
        : Routes.PORTFOLIO_ADD_INVEST_INVESTMENT_PLAN,
    );
  };

  const onPortfolioSelect = (value: Portfolio): void => {
    setPortfolio?.(value);
  };

  const tmdDetailsByPortfolio = {
    [SaverPortfolio.Enum.UNKNOWN]: undefined,
    [SaverPortfolio.Enum.INDEX]: tmdDetailsForPortfolio(
      SaverPortfolio.Enum.INDEX,
      saverTMDDetails?.data?.contact,
    ),
    [SaverPortfolio.Enum.UNIVERSE]: tmdDetailsForPortfolio(
      SaverPortfolio.Enum.UNIVERSE,
      saverTMDDetails?.data?.contact,
    ),
    [SaverPortfolio.Enum.EARTH]: tmdDetailsForPortfolio(
      SaverPortfolio.Enum.EARTH,
      saverTMDDetails?.data?.contact,
    ),
    [SaverPortfolio.Enum.GALAXY]: tmdDetailsForPortfolio(
      SaverPortfolio.Enum.GALAXY,
      saverTMDDetails?.data?.contact,
    ),
    [SaverPortfolio.Enum.EXPLORER]: tmdDetailsForPortfolio(
      SaverPortfolio.Enum.EXPLORER,
      saverTMDDetails?.data?.contact,
    ),
  };

  return (
    <PortfolioSelectionContext.Provider
      value={{
        variant: 'add',
        navigateToPortfolioSelection: () => {
          navigate(Routes.PORTFOLIO_ADD);
        },
        navigateToTMDForm: () => {
          navigate(Routes.PORTFOLIO_ADD_TMD);
        },
        portfolio,
        currentPortfolios,
        onPortfolioSelect,
        onTMDEligible,
        saverTMDDetails,
        pdsLink,
        tmdLink,
        tmdDetailsByPortfolio,
        portfolioIcon,
      }}
    >
      {children}
    </PortfolioSelectionContext.Provider>
  );
};

export const usePortfolioSelection = (): PortfolioSelection => {
  const context = useContext(PortfolioSelectionContext);

  if (!context) {
    throw new Error(
      'webapp: usePortfolioSelect must be wrapped with <OnboardingPortfolioSelectProvider /> or <AddNewPortfolioSelectionProvider />',
    );
  }

  return context;
};
