import { Box } from '@spaceship-fspl/components';
import { TransactionStatus } from '@spaceship-fspl/graphql/src/__generated__/globalTypes';
import { FeatherAlertTriangleIcon } from '@spaceship-fspl/icons-web';
import { getColor } from '@spaceship-fspl/styles';
import React, { Fragment } from 'react';

const FailedTransactionStatuses = [
  TransactionStatus.REJECTED,
  TransactionStatus.CANCELLED,
  TransactionStatus.FAILED,
];

export const isFailedTransaction = (status: TransactionStatus): boolean =>
  FailedTransactionStatuses.some((failedStatus) => failedStatus === status);

interface TransactionStatusIndicatorProps {
  status: TransactionStatus;
}

const steps = [
  {
    status: TransactionStatus.PENDING,
    color: getColor('neutral.080'),
  },
  {
    status: TransactionStatus.TRANSFERRING,
    color: getColor('gold.100'),
  },
  {
    status: TransactionStatus.PAID,
    color: getColor('mint.050'),
  },
];

const defaultStepColor = getColor('neutral.050');

export const TransactionStatusIndicator: React.FC<
  React.PropsWithChildren<TransactionStatusIndicatorProps>
> = ({ status }) => {
  const statusStep = steps.findIndex((step) => step.status === status);

  if (!isFailedTransaction(status)) {
    return (
      <Box display="flex" alignItems="center">
        <svg
          width="24"
          height="6"
          viewBox="0 0 24 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {steps.map((step, index) => (
            <Fragment key={step.status}>
              {index < steps.length - 1 && (
                <rect
                  x={9 * index + 5}
                  y="2"
                  width="5"
                  height="2"
                  fill={
                    index + 1 <= statusStep
                      ? steps[statusStep]?.color
                      : defaultStepColor
                  }
                />
              )}
              <circle
                cx={9 * index + 3}
                cy="3"
                r="3"
                fill={
                  index <= statusStep
                    ? steps[statusStep]?.color
                    : defaultStepColor
                }
              />
            </Fragment>
          ))}
        </svg>
      </Box>
    );
  }

  if (isFailedTransaction(status)) {
    return (
      <Box lineHeight={0} width={24}>
        <FeatherAlertTriangleIcon size="sm" color="red.100" />
      </Box>
    );
  }

  return null;
};
