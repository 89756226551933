import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import { useLogout } from '@spaceship-fspl/auth';
import {
  Box,
  Columns,
  Divider,
  Heading,
  Inline,
  PowerUpPanel,
  Stack,
  Text,
  useSetEqualClientHeights,
} from '@spaceship-fspl/components';
import {
  fromProtoPortfolio,
  saverPortfolioInformationScalars,
  SaverTMDResult,
  useSaverPortfolioFlags,
} from '@spaceship-fspl/graphql';
import { WebAppVoyagerSelectPortfolio } from '@spaceship-fspl/graphql/src/__generated__/WebAppVoyagerSelectPortfolio';
import {
  calcInceptionToPerformanceAsAtDateInMonths,
  capitalizeFirstLetter,
  formatDate,
  formatPercentage,
  InternalRoutes,
} from '@spaceship-fspl/helpers';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import { FeatherAlertTriangleIcon } from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderRadius,
  getColor,
  getSpace,
  marginTop,
  match,
  paddingX,
  paddingY,
} from '@spaceship-fspl/styles';
import { useTrack } from '@spaceship-fspl/tracking';
import { SaverPortfolio } from '@spaceship-fspl/types/externalapi';
import { Error } from 'components/layouts/error';
import {
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { RadioCard } from 'components/radio-card';
import { ResourceList } from 'components/resource-list';
import { VoyagerInvestableAssetsModal } from 'components/voyager-investable-assets-modal';
import { GaugeIcon, PieChartIcon } from 'components/voyager-portfolio-icons';
import { VoyagerPortfolioMixModal } from 'components/voyager-portfolio-mix-modal';
import { useNotifications } from 'contexts/notifications';
import { usePortfolioSelection } from 'contexts/saver/portfolio-selection';
import { parse } from 'date-fns';
import { MarketingTrackingEvents } from 'helpers/analytics';
import { GENERIC_ERROR_MESSAGE, PORTFOLIO_PERFORMANCE } from 'helpers/errors';
import { voyagerPortfolios } from 'helpers/portfolios';
import { requiredValidation } from 'helpers/validation';
import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

const PORTFOLIO_OPTIONS = [
  voyagerPortfolios.INDEX,
  voyagerPortfolios.UNIVERSE,
  voyagerPortfolios.EARTH,
  voyagerPortfolios.GALAXY,
  voyagerPortfolios.EXPLORER,
];

export enum VoyagerPopupModals {
  INVESTABLE_ASSETS = 'investable-assets',
  PORTFOLIO_MIX = 'portfolio-mix',
}

const PortfolioCard = styled.div`
  ${match('xl')`
    min-width: calc(140vw / 4);
    max-width: calc(140vw / 4);
`}
  ${match('xxl')`
    min-width: calc(140vw / 5);
    max-width: calc(140vw / 5);
`}
  ${match('xxxxl')`
    min-width: calc(140vw / 8);
    max-width: calc(140vw / 8);
`}
  margin-right: ${getSpace('md')};
  margin-left: ${getSpace('md')};
  margin-bottom: ${getSpace('md')};
`;

const ScrollArea = styled.div`
  padding-top: ${getSpace('md')};
  padding-bottom: ${getSpace('md')};
  ${match('md')`
    // https://stackoverflow.com/a/63507769
    transform: rotateX(180deg); // moves slider to the top.
    overflow-x: auto;
    postion: relative;
    width: 100%;
    display: block;
  `}
  transform: none;
  overflow-x: visible;
  display: flex;
  flex-wrap: wrap;
`;

const PortfoliosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow-x: inherit;
  ::-webkit-scrollbar {
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${getColor('neutral.015')};
  }

  ::-webkit-scrollbar-thumb {
    background: ${getColor('neutral.015')};
    border-radius: 10px;
  }

  :hover::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb:hover {
    background: ${getColor('neutral.050')};
  }
  ${match('md')`
      // https://stackoverflow.com/a/63507769
      transform: rotateX(180deg); // correct the flip
  `};
  ${match('lg')`
      width: 250%;
      justify-content: center;

      display: flex;
      flex-wrap: nowrap;
      padding-bottom: ${getSpace('md')};
  `};
  ${match('xl')`
    width: 200%;
  `}
`;

const Form = styled.form`
  ${match('lg')`
      width: '80%',
      margin: '0 auto',
      overflow: 'hidden',
  `}
`;

const Footer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-left: ${getSpace('md')};
  margin-right: ${getSpace('md')};
  ${match('lg')`
    width: 80%;
    margin: 0 auto ${getSpace('lg')} auto;
  `}
  justify-content: space-between;
`;

export const VoyagerSelectPortfolio: React.FC<
  React.PropsWithChildren<RouteComponentProps>
> = () => {
  const [currentPortfolioName, setCurrentPortfolioName] = useState('');
  const [investableAssetsModalPortfolio, setInvestableAssetsPortfolioModal] =
    useState<SaverPortfolio.Enum | null>(null);
  const [portfolioMixModalPortfolio, setPortfolioMixModalPortfolio] =
    useState<SaverPortfolio.Enum | null>(null);
  const track = useTrack();
  const logout = useLogout();
  const {
    variant,
    currentPortfolios,
    onPortfolioSelect,
    tmdDetailsByPortfolio,
    onTMDEligible,
    navigateToTMDForm,
  } = usePortfolioSelection();

  const handleTooltipModal = (
    e: React.SyntheticEvent,
    {
      title,
      modal,
      portfolio,
      isDisabled,
    }: {
      modal: VoyagerPopupModals;
      title: string;
      portfolio: SaverPortfolio.Enum;
      isDisabled: boolean | undefined;
    },
  ): void => {
    if (isDisabled) {
      return;
    }

    e.preventDefault();
    setCurrentPortfolioName(title);

    switch (modal) {
      case VoyagerPopupModals.INVESTABLE_ASSETS:
        setInvestableAssetsPortfolioModal(portfolio);
        break;
      case VoyagerPopupModals.PORTFOLIO_MIX:
        setPortfolioMixModalPortfolio(portfolio);
    }
  };

  const {
    formState: { errors },
    register,
    watch,
    handleSubmit,
  } = useForm<{
    portfolio: Exclude<SaverPortfolio.Enum, SaverPortfolio.Enum.UNKNOWN>;
    pds: boolean;
  }>({
    shouldFocusError: true,
    mode: 'onBlur',
  });
  const { popToast } = useNotifications();
  const flagsData = useSaverPortfolioFlags();
  const resp = useQuery<WebAppVoyagerSelectPortfolio>(
    gql`
      query WebAppVoyagerSelectPortfolio {
        saverPortfolioInformations {
          id
          ...voyagerPortfolioInformationFields
        }
      }
      ${saverPortfolioInformationScalars}
    `,
    {
      onError: () => {
        popToast({
          message: PORTFOLIO_PERFORMANCE,
          level: 'error',
        });
      },
    },
  );

  const portfolioValue = watch('portfolio');
  const portfolioEnum = fromProtoPortfolio(Number(portfolioValue));
  const selectedPortfolio = portfolioEnum
    ? voyagerPortfolios[portfolioEnum]
    : undefined;

  const pdsLink = selectedPortfolio?.pds ?? InternalRoutes.IMPORTANT_DOCUMENTS;
  const tmdLink = selectedPortfolio?.tmd ?? InternalRoutes.IMPORTANT_DOCUMENTS;

  const onSubmit = handleSubmit(async ({ portfolio }) => {
    if (variant === 'onboarding') {
      track?.(MarketingTrackingEvents.VOYAGER_ONBOARDING_PORTFOLIO_CONFIRM);
    }
    onPortfolioSelect(Number(portfolio));
    const selectedProductTMDDetails =
      portfolio && tmdDetailsByPortfolio[portfolio];
    if (
      selectedProductTMDDetails?.result === SaverTMDResult.OK ||
      selectedProductTMDDetails?.result === SaverTMDResult.MANUALLY_APPROVED ||
      selectedProductTMDDetails?.result === SaverTMDResult.PRE_APPROVED
    ) {
      onTMDEligible();
    } else {
      navigateToTMDForm();
    }
  });

  const portfolioOptions = PORTFOLIO_OPTIONS;
  const saverPortfolioFlags = flagsData.data?.saverPortfolioFlags;
  const createProductInstanceAvailable =
    saverPortfolioFlags?.createProductInstanceAvailable ?? true;
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // sets the scroll position so its clearer to the user that they can scroll
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = window.innerWidth / 4;
    }
  }, []);
  useEffect(() => {
    const handleResize = (): void => {
      // sets the scroll position so its clearer to the user that they can scroll
      if (scrollRef.current) {
        scrollRef.current.scrollLeft = window.innerWidth / 4;
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const descriptionRefs = useMemo(() => {
    return portfolioOptions.map(() => createRef<HTMLDivElement>());
  }, [portfolioOptions]);
  useSetEqualClientHeights(descriptionRefs, !!resp.data);

  const investmentPerformanceRefs = useMemo(() => {
    return portfolioOptions.map(() => createRef<HTMLDivElement>());
  }, [portfolioOptions]);
  useSetEqualClientHeights(investmentPerformanceRefs, !!resp.data);

  const summaryRefs = useMemo(() => {
    return portfolioOptions.map(() => createRef<HTMLDivElement>());
  }, [portfolioOptions]);
  useSetEqualClientHeights(summaryRefs, !!resp.data);

  if (!createProductInstanceAvailable) {
    return (
      <Error
        title="Select your portfolio"
        subtitle={
          saverPortfolioFlags?.createProductInstanceNotAvailableMessage ??
          GENERIC_ERROR_MESSAGE
        }
        iconColor="indigo.070"
        buttonText="Go back"
        onContinue={{
          onClick: () => {
            if (variant === 'onboarding') {
              logout();
            } else {
              window.history.back();
            }
          },
          trackingProperties: {
            name: 'create_product_instance_not_available_go_back',
          },
        }}
      />
    );
  }

  return (
    <>
      <VoyagerInvestableAssetsModal
        portfolio={investableAssetsModalPortfolio}
        portfolioName={currentPortfolioName}
        showModal={!!investableAssetsModalPortfolio}
        closeModal={() => setInvestableAssetsPortfolioModal(null)}
      />

      <VoyagerPortfolioMixModal
        portfolio={portfolioMixModalPortfolio}
        portfolioName={currentPortfolioName}
        showModal={!!portfolioMixModalPortfolio}
        closeModal={() => setPortfolioMixModalPortfolio(null)}
      />

      <Form onSubmit={onSubmit}>
        <Stack spaceY="xl">
          <Columns alignX="center" spaceY="xl">
            <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
              <PageHeading
                title="Select your portfolio"
                subtitle={`Invest in one of our professionally-managed portfolios made up of the companies you know and use every day.`}
              />
            </Columns.Column>

            {!!errors.portfolio?.message && (
              <Box marginBottom="md">
                <Inline spaceX="xs" alignY="center" alignX="center">
                  <Box lineHeight={0}>
                    <FeatherAlertTriangleIcon size="md" color="red.100" />
                  </Box>
                  <Text color="red.100" variant={2} isBold>
                    {errors.portfolio.message}
                  </Text>
                </Inline>
              </Box>
            )}
            <ScrollArea ref={scrollRef}>
              <PortfoliosContainer>
                {portfolioOptions
                  .sort((a, b) => {
                    const weightA = currentPortfolios?.includes(a.portfolio)
                      ? 1
                      : 0; // Assign weight 1 if you have the portfolio, otherwise 0
                    const weightB = currentPortfolios?.includes(b.portfolio)
                      ? 1
                      : 0; // Assign weight 1 if you have the portfolio, otherwise 0

                    // Sort by weight first, then by portfolio enum
                    if (weightA === weightB) {
                      return a.portfolio === b.portfolio
                        ? 0
                        : a.portfolio < b.portfolio
                          ? -1
                          : 1;
                    } else {
                      return weightA - weightB; // Sort by weight, with portfolios you have coming later
                    }
                  })
                  .map(
                    (
                      {
                        portfolio,
                        newFeesFallback,
                        icon,
                        targetReturn,
                        targetReturnGauge,
                        investmentRiskSummary,
                        investmentRisk,
                        investmentRiskGauge,
                        suggestedTimeframe,
                        shareOfInvestableAssetsStocks,
                        shareOfInvestableAssetsStocksPieChart,
                        portfolioMix,
                        portfolioMixOther,
                        investmentPerformanceIn1Yr,
                        title,
                        name,
                      },
                      i,
                    ) => {
                      const isCurrentPortfolio =
                        currentPortfolios?.includes(portfolio);
                      const gqlPortfolio = fromProtoPortfolio(portfolio);
                      const portfolioInformation =
                        resp.data?.saverPortfolioInformations?.find(
                          (info) => info?.portfolio === gqlPortfolio,
                        );
                      const Icon = icon.stars;
                      const inceptionDate = portfolioInformation?.inceptionDate
                        ? parse(
                            portfolioInformation.inceptionDate,
                            'd MMMM yyyy',
                            new Date(),
                          )
                        : undefined;
                      const performanceAsAt =
                        portfolioInformation?.performanceAsAt
                          ? parse(
                              portfolioInformation.performanceAsAt,
                              'd MMMM yyyy',
                              new Date(),
                            )
                          : undefined;

                      return (
                        <PortfolioCard key={portfolio}>
                          <RadioCard
                            data-testid={`${portfolio}-card`}
                            paddingRight={{ lg: 'md' }}
                            value={portfolio}
                            error={!!errors?.portfolio?.message}
                            disabled={isCurrentPortfolio}
                            {...register(
                              'portfolio',
                              requiredValidation('Portfolio'),
                            )}
                          >
                            <Box
                              aria-label={portfolioInformation?.title.toLowerCase()}
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-between"
                              height="100%"
                            >
                              <Stack spaceY="sm">
                                <Box ref={descriptionRefs[i]}>
                                  <Stack spaceY="lg">
                                    <Box position="relative">
                                      <Box
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        <Icon color="indigo.070" size="xxxl" />
                                      </Box>

                                      {isCurrentPortfolio && (
                                        <StyledUnavailableStatus>
                                          You&apos;ve already signed up to this
                                          portfolio
                                        </StyledUnavailableStatus>
                                      )}
                                    </Box>

                                    <Stack spaceY="xxs">
                                      <Heading
                                        component="h3"
                                        variant={4}
                                        isBold
                                      >
                                        {portfolioInformation?.title}
                                      </Heading>
                                      <Text variant={3}>
                                        {portfolioInformation?.description}
                                      </Text>
                                    </Stack>
                                  </Stack>
                                </Box>

                                <Divider color="neutral.050" />
                                <Box ref={investmentPerformanceRefs[i]}>
                                  {portfolioInformation?.oneYearPerformancePercentage ===
                                    'N/A' &&
                                  investmentPerformanceIn1Yr &&
                                  inceptionDate ? (
                                    <Box flexGrow={1}>
                                      <Stack spaceY="xs">
                                        <Stack spaceY="xxxs">
                                          <SectionHeading>
                                            Investment performance
                                          </SectionHeading>
                                          <Text variant={4} color="neutral.080">
                                            {investmentPerformanceIn1Yr}
                                          </Text>
                                        </Stack>
                                      </Stack>
                                    </Box>
                                  ) : (
                                    <>
                                      <Stack spaceY="xs">
                                        <Stack spaceY="xxxs">
                                          <SectionHeading>
                                            Investment performance
                                          </SectionHeading>

                                          <Text
                                            variant={4}
                                            color="neutral.080"
                                            isBold
                                          >
                                            {portfolioInformation?.performanceAsAt
                                              ? `As at ${formatDate(
                                                  portfolioInformation.performanceAsAt,
                                                  'dd MMMM yyyy',
                                                )}`
                                              : ''}
                                          </Text>
                                        </Stack>

                                        <SectionData
                                          data={[
                                            {
                                              label: '1 year performance',
                                              value:
                                                portfolioInformation?.oneYearPerformancePercentage
                                                  ? formatPercentage(
                                                      portfolioInformation.oneYearPerformancePercentage,
                                                      2,
                                                    )
                                                  : '—',
                                              note:
                                                portfolioInformation?.oneYearPerformancePercentage &&
                                                portfolioInformation?.performanceAsAt
                                                  ? `Over the year ending ${formatDate(
                                                      portfolioInformation.performanceAsAt,
                                                      'dd MMMM yyyy',
                                                    )}`
                                                  : '',
                                            },
                                            {
                                              label:
                                                'Annualised performance since Funded Date',
                                              value:
                                                portfolioInformation?.sinceInceptionPerformancePercentage
                                                  ? `${formatPercentage(
                                                      portfolioInformation.sinceInceptionPerformancePercentage,
                                                      2,
                                                    )} pa`
                                                  : '—',
                                              note:
                                                inceptionDate && performanceAsAt
                                                  ? `${formatDate(
                                                      inceptionDate,
                                                      'dd MMMM yyyy',
                                                    )} to ${formatDate(
                                                      performanceAsAt,
                                                      'dd MMMM yyyy',
                                                    )} (${calcInceptionToPerformanceAsAtDateInMonths(
                                                      inceptionDate,
                                                      performanceAsAt,
                                                    )} months)`
                                                  : '',
                                            },
                                          ]}
                                        />
                                      </Stack>

                                      <Text
                                        variant={4}
                                        color="neutral.085"
                                        isItalic
                                      >
                                        Past performance is not a reliable
                                        indicator of future performance.
                                      </Text>
                                    </>
                                  )}
                                </Box>
                                <Divider color="neutral.050" />

                                <Box ref={summaryRefs[i]}>
                                  <Stack spaceY="xs">
                                    <Stack spaceY="xxxs">
                                      <SectionHeading>Summary</SectionHeading>

                                      <Text variant={4} color="neutral.080">
                                        {investmentRiskSummary}
                                      </Text>
                                    </Stack>

                                    <SectionData
                                      data={[
                                        {
                                          label: 'Target return',
                                          value: targetReturn,
                                          icon: (
                                            <GaugeIcon
                                              value={targetReturnGauge}
                                              width="44px"
                                            />
                                          ),
                                        },
                                        {
                                          label: 'Investment risk',
                                          value: investmentRisk,
                                          icon: (
                                            <GaugeIcon
                                              value={investmentRiskGauge}
                                              width="44px"
                                            />
                                          ),
                                        },
                                        {
                                          label: 'Minimum timeframe',
                                          value: suggestedTimeframe,
                                        },
                                      ]}
                                    />

                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <Box display="flex">
                                        <Text variant={3}>
                                          Share of investable <br />
                                          assets
                                        </Text>

                                        <Box
                                          marginLeft="xxs"
                                          data-testid={`${name}-${VoyagerPopupModals.INVESTABLE_ASSETS}`}
                                          onClick={(e) =>
                                            handleTooltipModal(e, {
                                              title,
                                              portfolio,
                                              isDisabled: isCurrentPortfolio,
                                              modal:
                                                VoyagerPopupModals.INVESTABLE_ASSETS,
                                            })
                                          }
                                        >
                                          <FeatherInfoIcon
                                            color="neutral.070"
                                            size="md"
                                          />
                                        </Box>
                                      </Box>

                                      <Box
                                        display="flex"
                                        alignItems="flex-start"
                                      >
                                        <Box marginRight="xxs">
                                          <PieChartIcon
                                            value={
                                              shareOfInvestableAssetsStocksPieChart
                                            }
                                            width="24"
                                            height="24"
                                          />
                                        </Box>

                                        <Text variant={3} isBold>
                                          {shareOfInvestableAssetsStocks}
                                        </Text>
                                      </Box>
                                    </Box>

                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <Box display="flex">
                                        <Text variant={3}>
                                          Target portfolio mix
                                        </Text>

                                        <Box
                                          marginLeft="xxs"
                                          data-testid={`${name}-${VoyagerPopupModals.PORTFOLIO_MIX}`}
                                          onClick={(e) =>
                                            handleTooltipModal(e, {
                                              title,
                                              portfolio,
                                              isDisabled: isCurrentPortfolio,
                                              modal:
                                                VoyagerPopupModals.PORTFOLIO_MIX,
                                            })
                                          }
                                        >
                                          <FeatherInfoIcon
                                            color="neutral.070"
                                            size="md"
                                          />
                                        </Box>
                                      </Box>

                                      <Text variant={3} isBold align="right">
                                        {portfolioMix}{' '}
                                        {portfolioMixOther ? (
                                          <>
                                            <br />
                                            {portfolioMixOther}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </Text>
                                    </Box>
                                  </Stack>
                                </Box>

                                <Divider color="neutral.050" />

                                <Stack spaceY="xxs">
                                  <SectionHeading>
                                    Management fees and costs
                                  </SectionHeading>

                                  <SectionData
                                    data={[
                                      {
                                        label: 'Monthly fee',
                                        value:
                                          portfolioInformation?.accountFee ??
                                          newFeesFallback.accountFee,
                                        note: capitalizeFirstLetter(
                                          portfolioInformation?.accountFeeBalanceCopy ??
                                            newFeesFallback.accountFeeBalanceCopy,
                                        ),
                                        notes: [
                                          capitalizeFirstLetter(
                                            portfolioInformation?.accountFeeAllPorfoliosCopy ??
                                              newFeesFallback.accountFeeAllPorfoliosCopy,
                                          ),
                                        ],
                                      },
                                      {
                                        label: 'Management fee',
                                        value:
                                          portfolioInformation?.managementFee ??
                                          newFeesFallback.managementFee,
                                        note: capitalizeFirstLetter(
                                          portfolioInformation?.managementFeeCopy ??
                                            newFeesFallback.managementFeeCopy,
                                        ),
                                      },
                                    ]}
                                  />
                                  {portfolioInformation?.powerUp ? (
                                    <Box marginTop={'lg'}>
                                      <PowerUpPanel>
                                        <Text
                                          variant={2}
                                          isBold
                                          align={'center'}
                                        >
                                          {portfolioInformation.powerUp}
                                        </Text>
                                      </PowerUpPanel>
                                    </Box>
                                  ) : null}
                                </Stack>
                              </Stack>
                            </Box>
                          </RadioCard>
                        </PortfolioCard>
                      );
                    },
                  )}
              </PortfoliosContainer>
            </ScrollArea>
          </Columns>
        </Stack>
        <Footer>
          <Box marginY={'md'}>
            <Text variant={2} color="neutral.085" align="center">
              All fees and costs are inclusive of GST and net of expected
              reduced input tax credits. Third-party fees may apply. Find out
              more by reading the PDS.
              <br /> <br />
              This information is general in nature as it has been prepared
              without taking account of your objectives, financial situation or
              needs. You should assess your financial situation and needs,
              consider seeking professional financial advice, and read the PDS
              and other important documents before making an investment
              decision.
            </Text>
          </Box>

          <Box marginY={'md'}>
            <Columns alignX="center">
              <Columns.Column width={{ xs: 1, md: 1 / 2 }}>
                <Stack spaceY="md">
                  <Stack spaceY="sm">
                    <Heading variant={4} isBold component="h3">
                      Sign up{' '}
                      {selectedPortfolio?.title
                        ? `for the ${selectedPortfolio.title}`
                        : `to a${
                            variant === 'add' ? ' new' : ''
                          } Spaceship Voyager portfolio`}
                    </Heading>

                    <Text variant={2}>
                      By clicking the{' '}
                      <strong>&apos;Confirm selection&apos;</strong> button, I
                      confirm I understand and accept the important documents
                      below and confirm that the product is consistent with my
                      objectives, financial situation and needs.
                    </Text>
                  </Stack>

                  <Box>
                    <Divider color="neutral.050" />

                    <ResourceList
                      resources={[
                        {
                          label: 'Product Disclosure Statement',
                          link: pdsLink,
                        },
                        {
                          label: 'Reference Guide',
                          link: InternalRoutes.REFERENCE_GUIDE,
                        },
                        {
                          label: 'Target Market Determination Statement',
                          link: tmdLink,
                        },
                      ]}
                    />

                    <Divider color="neutral.050" />
                  </Box>
                </Stack>
              </Columns.Column>
            </Columns>
          </Box>
          <Box marginY={'md'}>
            <PageFormButtonContainer>
              <PageFormContinueButton
                data-testid="confirm-portfolio-selection"
                trackingProperties={{
                  name:
                    variant === 'onboarding'
                      ? 'signup_portfolio_submit'
                      : 'portfolio_registration_select_portfolio_submit',
                }}
              >
                Confirm selection
              </PageFormContinueButton>
            </PageFormButtonContainer>
          </Box>
        </Footer>
      </Form>
    </>
  );
};

const SectionHeading: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Heading component="h4" variant={5} isBold>
    {children}
  </Heading>
);

interface SectionDataProps {
  data: Array<{
    icon?: React.ReactNode;
    label: string;
    value: string;
    note?: string;
    notes?: Array<string>;
  }>;
}

const SectionData: React.FC<React.PropsWithChildren<SectionDataProps>> = ({
  data,
}) => {
  return (
    <Stack spaceY="xs">
      {data.map(({ icon, label, value, note, notes }) => (
        <Stack spaceY="xxxs" key={label}>
          <Box display="flex">
            <Box flex={1} marginRight="md">
              <Text variant={3}>{label}</Text>
            </Box>
            <Box display="flex" alignItems="flex-start">
              {icon ? <Box marginRight="xxs">{icon}</Box> : null}
              <Text variant={3} isBold>
                {value}
              </Text>
            </Box>
          </Box>

          {note && (
            <Text variant={4} color="neutral.085">
              {note}
            </Text>
          )}

          {notes && notes?.length == 1 && (
            <Text variant={4} color="neutral.085">
              {notes[0]}
            </Text>
          )}

          {notes && notes?.length > 1 && (
            <StyledList component="ul">
              {notes.map((n, index) => (
                <li key={index}>{n}</li>
              ))}
            </StyledList>
          )}
        </Stack>
      ))}
    </Stack>
  );
};

const StyledUnavailableStatus = styled(Text).attrs({
  component: 'span',
  variant: 4,
  color: 'neutral.000',
})`
  ${backgroundColor('neutral.100')}
  ${borderRadius('sm')}
  ${paddingX('xxs')}
  ${paddingY('xxxs')}
  font-weight: 700;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const StyledList = styled(Text).attrs({
  color: 'neutral.085',
  variant: 4,
})`
  ${marginTop('xxs')}
  padding-inline-start: 18px;
`;
