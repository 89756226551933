import { Box } from '@spaceship-fspl/components';
import {
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  getColor,
  PaddingProps,
  transition,
  zIndex,
} from '@spaceship-fspl/styles';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { RadioProps } from './radio';

export type RadioCardProps = Omit<RadioProps, 'radioPosition'> & {
  contentPadding?: PaddingProps;
  footer?: React.ReactNode;
};

const RadioCard: React.ForwardRefRenderFunction<
  HTMLInputElement,
  RadioCardProps
> = (
  {
    children,
    defaultChecked = false,
    error,
    name,
    value,
    contentPadding,
    footer,
    ...props
  },
  ref,
) => {
  const id = `${name}_${value}`;

  return (
    <Box position="relative" height="100%">
      <StyledRadioInput
        defaultChecked={defaultChecked}
        type="radio"
        id={id}
        name={name}
        value={value}
        {...{ ...props, ref }}
      />
      <StyledBox
        isDisabled={props.disabled}
        hasError={!!error}
        backgroundColor="neutral.000"
        borderRadius="sm"
        boxShadow="sm"
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <StyledLabel htmlFor={id}>
          <StyledRadioBox>
            <Check />
          </StyledRadioBox>

          <Box
            padding={{ xs: 'md', md: 'lg' }}
            height="100%"
            {...contentPadding}
          >
            {children}
          </Box>
        </StyledLabel>

        {footer}
      </StyledBox>
    </Box>
  );
};

const ForwardedRadioCard = forwardRef<HTMLInputElement, RadioCardProps>(
  RadioCard,
);
export { ForwardedRadioCard as RadioCard };

const Check: React.FC<React.PropsWithChildren> = () => (
  <StyledCheck>
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle r="12" cx="12" cy="12" fill={getColor('indigo.070')} />
      <path
        d="M11.1788 15.3706L16.3703 8.33861L17.4524 9.11223L11.4708 17.2143L7.10401 14.0924L7.89407 13.0222L11.1788 15.3706Z"
        fill={getColor('neutral.000')}
        stroke={getColor('neutral.000')}
      />
    </svg>
  </StyledCheck>
);

const checkSizeStyle = css`
  height: 24px;
  width: 24px;
`;

const StyledRadioInput = styled.input`
  position: absolute;
  opacity: 0;
`;

const StyledCheck = styled.div`
  ${checkSizeStyle};
  ${zIndex(1)}
  display: none;
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledRadioBox = styled(Box)`
  ${checkSizeStyle};
  position: absolute;
  top: 24px;
  right: 24px;

  ::before {
    ${transition}
    ${borderColor('neutral.050')}
    ${borderRadius('xs')}
    ${borderWidth('md')}
    ${zIndex(0)}
    border-style: solid;
    box-sizing: border-box;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
`;

const StyledLabel = styled.label`
  height: 100%;
  cursor: pointer;
`;

const StyledBox = styled(Box)<{
  hasError: boolean;
  isDisabled?: boolean;
}>`
  ${({ hasError }) => borderColor(hasError ? 'red.100' : 'neutral.000')}
  ${borderWidth('md')}
  ${transition}
  border-style: solid;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};

  ${StyledRadioBox}::before {
    ${({ hasError }) => borderColor(hasError ? 'red.100' : 'neutral.050')}
  }

  ${StyledRadioInput}:not(:disabled, :checked) ~ &:hover,
  ${StyledRadioInput}:focus:not(:disabled, :checked) ~ & {
    ${({ hasError }) => (!hasError ? borderColor('indigo.020') : '')}

    ${StyledRadioBox}::before {
      ${({ hasError }) => (!hasError ? borderColor('indigo.020') : '')}
    }
  }

  ${StyledRadioInput}:checked ~ & {
    ${borderColor('indigo.070')}

    ${StyledCheck} {
      display: block;
    }
  }

  ${StyledRadioInput}:disabled ~ & {
    ${StyledRadioBox}::before {
      ${backgroundColor('neutral.030')}
      ${borderColor('neutral.050')}
    }

    ${StyledCheck} {
      display: none;
    }
  }
`;
