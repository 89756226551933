import { MixinFunctionValue as MFV } from '@spaceship-fspl/substance-mixin';
import {
  createMap,
  createMatch,
  ResponsiveValue as RV,
  ResponsiveValueConstraint,
  StyleProperty,
} from '@spaceship-fspl/substance-style';

export type Media = keyof typeof queries;
export type ResponsiveValue<Value extends ResponsiveValueConstraint> = RV<
  Media,
  Value
>;
export type MixinFunctionValue<Property extends StyleProperty> = MFV<
  Media,
  Property
>;

export const queries = {
  xs: '(min-width: 0em)',
  sm: `(min-width: 23.5625em)`, // 377px
  md: `(min-width: 34.8125em)`, // 557px
  lg: `(min-width: 50.5625em)`, // 809px
  xl: `(min-width: 75.0625em)`, // 1201px
  xxl: `(min-width: 87.5625em)`, // 1401px
  xxxl: `(min-width: 100em)`, // 1600px
  xxxxl: `(min-width: 120em)`, // 1920px
};

export const match = createMatch(queries);
export const map = createMap(match);
